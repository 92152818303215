@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */



.App {
  text-align: center;
}

.Toastify__toast--warning {
  background: #dfbe36;
  color: #fff;
}

.Toastify__toast--error {
  background: #e74c3c;
  color: #fff;
}

.Toastify__toast--success {
  background: #07bc0c;
  color: #fff;
}

.Toastify__toast--success h1 {
  color: #fff;
}

.Toastify__toast-icon {
  display: none;
}

.margin-0 {
  margin: 0px !important;
}

.padding-0 {
  margin: 0px !important;
}

.paddingLeft-0 {
  padding-left: 0px !important;
}

.paddingLeft-5 {
  padding-left: 5px !important;
}

.paddingLeft-10 {
  padding-left: 10px !important;
}

.paddingLeft-15 {
  padding-left: 15px !important;
}

.paddingLeft-20 {
  padding-left: 20px !important;
}

.paddingRight-0 {
  padding-right: 0px !important;
}

.paddingRight-5 {
  padding-right: 5px !important;
}

.paddingRight-10 {
  padding-right: 10px !important;
}

.paddingRight-15 {
  padding-right: 15px !important;
}

.paddingRight-20 {
  padding-right: 20px !important;
}

.paddingTop-0 {
  padding-top: 0px !important;
}

.paddingTop-5 {
  padding-top: 5px !important;
}

.paddingTop-10 {
  padding-top: 10px !important;
}

.paddingTop-15 {
  padding-top: 15px !important;
}

.paddingTop-20 {
  padding-top: 20px !important;
}

.paddingBottom-0 {
  padding-bottom: 0px !important;
}

.paddingBottom-5 {
  padding-bottom: 5px !important;
}

.paddingBottom-10 {
  padding-bottom: 10px !important;
}

.paddingBottom-15 {
  padding-bottom: 15px !important;
}

.paddingBottom-20 {
  padding-bottom: 20px !important;
}

.marginLeft-0 {
  margin-left: 0px !important;
}

.marginLeft-5 {
  margin-left: 5px !important;
}

.marginLeft-10 {
  margin-left: 10px !important;
}

.marginLeft-15 {
  margin-left: 15px !important;
}

.marginLeft-20 {
  margin-left: 20px !important;
}

.marginRight-0 {
  margin-right: 0px !important;
}

.marginRight-5 {
  margin-right: 5px !important;
}

.marginRight-10 {
  margin-right: 10px !important;
}

.marginRight-15 {
  margin-right: 15px !important;
}

.marginRight-20 {
  margin-right: 20px !important;
}

.marginBottom-0 {
  margin-bottom: 0px !important;
}

.marginBottom-5 {
  margin-bottom: 5px !important;
}

.marginBottom-10 {
  margin-bottom: 10px !important;
}

.marginBottom-15 {
  margin-bottom: 15px !important;
}

.marginBottom-20 {
  margin-bottom: 20px !important;
}

.marginTop-0 {
  margin-top: 0px !important;
}

.marginTop-5 {
  margin-top: 5px !important;
}

.marginTop-10 {
  margin-top: 10px !important;
}

.marginTop-15 {
  margin-top: 15px !important;
}

.marginTop-20 {
  margin-top: 20px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.countdown {
  height: 25px;
}

.width-fitContent {
  width: fit-content !important;
}

.width-maxContent {
  width: max-content !important;
}

.height-fitContent {
  height: fit-content !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.StudentAssignment__table tr {
  border-collapse: separate;
  border-spacing: 0 15px;

}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.W-15 {
  width: 15% !important;
}

.StudentAssignment__table1 th{
  border: 2px solid lightgreen;
  background-color : lightgreen;
  font-weight : bold;
  font-size :19px;
}
.StudentAssignment__table1 td{ 
  border-top:2px solid lightgreen;
  border-bottom: 2px solid lightgreen;
  cursor: pointer;
}

.StudentAssignment__table1Elements:hover{
  background-color : lightgreen;
}

.StudentAssignment__table1Start{
  border-left:2px solid lightgreen;
}

.StudentAssignment__table1End{
  border-right:2px solid lightgreen;
}


.StudentAssignment__table2 th{
  border: 2px solid lightcoral;
  background-color : lightcoral;
  font-weight : bold;
  font-size :19px;
}
.StudentAssignment__table2 td{ 
  border-top:2px solid lightcoral;
  border-bottom: 2px solid lightcoral;
  cursor: pointer;
}

.StudentAssignment__table2Elements:hover{
  background-color : lightcoral;
}

.StudentAssignment__table2Start{
  border-left:2px solid lightcoral;
}

.StudentAssignment__table2End{
  border-right:2px solid lightcoral;
}