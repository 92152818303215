body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Rajesh - CSS - 27.04.2023 */ 

.clickmeBtn  {
  border: 1px solid transparent;
  border-radius: 5px;
  color: #fff;
  background: #008000;
  display: inline-block;
  font-family: Segoe UI;
  font-size: 13px;
  font-weight: 600;
  height: 33px;
  letter-spacing: .8px;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 3px 8px;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.clickmeBtn:disabled{
  border: 1px solid transparent;
  border-radius: 5px;
  opacity: 0.5;
  cursor: not-allowed;
  color: #fff;
  background: #008000;
  display: inline-block;
  font-family: Segoe UI;
  font-size: 13px;
  font-weight: 600;
  height: 33px;
  letter-spacing: .8px;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 3px 8px;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.backBtn{
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: rgb(226, 2, 2);
  color: rgb(255, 255, 255);
  display: inline-block;
  font-family: Segoe UI;
  font-size: 13px;
  font-weight: 600;
  height: 33px;
  letter-spacing: .8px;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 3px 8px;
  text-align: center;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}