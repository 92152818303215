@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');



/* login :start here */

.kampus_Main_Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../Assets/login_bg.jpg') center center/cover no-repeat fixed;
}

#loginform {
  width: 30%;
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
  border-radius: 8px;
  font-family: 'Inter';
}

#headerTitle {
  text-align: center;
  font-family: 'Inter';
  padding: 1rem 0;
  margin: 0;
  font-size: 25px;
  font-weight: 900;
  color: #1e3164;
  letter-spacing: 0.5px;
}

.login_Inp_card {
  margin: 0px 0px !important;
  padding: 10px 25px;
}

.login_Inp_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  max-width: 100%;
  padding-bottom: 20px;
}

.login_Inp_card input {
  box-sizing: border-box;
  border: none !important;
  font-size: 14px;
  padding-left: 0px;
  padding-bottom: 10px;
  box-shadow: inset 0px -1px 0px 0px rgb(187 187 187 / 20%);
  transition: box-shadow 0.2s ease-in;
  border-bottom: 1px solid #ccc !important;
  margin-top: 5px;
}

.login_Inp_card input:focus {
  box-shadow: none !important;
  outline: none !important;
  border-bottom: 1px solid #3377FF !important;
}

.login_Inp_card input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.login_Inp_card input:hover::-webkit-input-placeholder,
.login_Inp_card input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.login_Inp_card label {
  align-self: start;
  padding-left: 0px;
  color: #1e3164;
  font-weight: 500;
}

.logBtn_card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.loginBtn_Qit {
  border-radius: 21px;
  color: white;
  font-weight: 700;
  background: #3377FF;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
  padding: 8px 40px;
  font-family: inter;
  font-size: 17px;
}

.loginBtn_Qit:hover {
  background-color: #2960ce;
}


/* login :end here */

/* start fetch book clarify and unclarify count jai */

.bookopenjai {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  padding: 7px 3px;
}

.bokopenjai {
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-right: 1px solid #8F9CAA;
  width: 100%;
}

.bookcoverd {
  padding-top: 0px;
}

.bookclarify2jai {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}

.Units_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #66747F;
}

.Portions_covered {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #8F9CAA;
}

.Clarify_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #8F9CAA;
}

.Y_T_Clarify {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #8F9CAA;
}

/* end fetch book clarify and unclarify count jai */


/* Dashboard Page css */


.D_Flex_Chapter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.h5_Eng {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #1B252F;
}

.P_Unit_S {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66747F;
}

.Session_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3377FF;
}

.clarify_dot {
  height: 7px;
  width: 7px;
  background-color: #B8C2CC;
  border-radius: 50%;
  display: inline-block;
  transform: rotate(-0.19deg);
}

.D_Flex_Div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.Mon_Apr {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #1B252F;
}


.D_Flex_Time {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #E6ECF1;
  padding-bottom: 15px;
}

.D_Flex_M_Profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0px 0px 0px;
}

.Meet_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
}

.Meet_Time_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #35414D;
}

.Meet_Manoj_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #35414D;
}

.Meet_E_T_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ADB9C7;
}

.Img_Gap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 48px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 30px;
  border: none;
}

.Button_J_M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.Button_Center {
  display: flex;
  justify-content: center;
}


.float-leftmOBI {
  float: left !important;
  margin-top: 30PX;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.Time_Apr {
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Time_Aprmobi {
  display: none;
}

.Time_Table {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #1B252F;
  letter-spacing: 1;
  padding: 1px 10px 0px 10px;
}

.Time_Table_BirthDay {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #1B252F;
  letter-spacing: 1;
  padding: 30px 10px 0px 10px;
}

.Apr_22 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66747F;
  letter-spacing: 1;
}

.column_Box {
  padding: 15px;
}

.column_Boxx {
  padding-left: 0px;
  padding-right: 0px;
}

.DateWise_tableData {
  height: 490px;
  overflow-y: scroll;
  padding-top: 10px;
}

.DateWise_tableData::-webkit-scrollbar {
  display: none;
}

.DateWise_tableData {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Background_Color {
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}


.Background_Color::-webkit-scrollbar {
  display: none;
}

.Background_Color {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Day {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ADB9C7;
}

.Date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ADB9C7;
}

.Tue_Day {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #1B252F;
}

.Tue_Date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #FFFFFF;
  background-color: #3377fe;
  border-radius: 50%;
  padding: 6px 7px;
  display: flex;
  align-items: center;
  width: 35px;
  justify-content: space-around;
  margin-left: 15%;
}

.Day_Date {
  margin: 10px;
  text-align: center;
  margin-bottom: 0px;
}

.timeTable_Home_scrn {
  margin-top: 100px;
  margin-bottom: 60px;
}

.schoolName {
  font-weight: 600;
  color: #000000;
  letter-spacing: 0.5px;
  font-size: 17px;
}

.timeTable_Home_scrns {
  margin-top: 0px;
  height: 470px;
  overflow-y: auto;
}

.timeTable_Home_scrns::-webkit-scrollbar {
  display: none;
}

.timeTable_Home_scrns {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.D_Flex {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Time {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
  padding: 0px 0px 0px 10px;
  width: 90px;
  margin-top: -8px;
}

.instructsTxt {
  box-sizing: border-box;
  margin: 20px;
  font-family: 'Inter';
  text-align: justify;
  font-size: 18px;
}

.instructsTxt li {
  margin-bottom: 15px;
}

.English_Time {
  width: 94%;
  height: 80px;
  border: 1px solid #EEF2F5;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px 1px 1px 3px;
  padding: 8px 15px 0px 15px;
  margin-left: 2%;
  margin-top: 5px;
}

.English_TimeMobi {
  width: 63%;
  height: 175px;
  border: 1px solid #EEF2F5;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px 1px 1px 3px;
  padding: 8px 15px 0px 15px;
  margin-left: 2%;
  margin-top: 5px;
}

.D_F_J_S {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 30px 0px 10px;
}

.Eng {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: black;
}

.AM_Time {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.AM_TimeMobi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.Unit_supplementarty {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2A004A;
}

.D_FLex_Unit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Cath_F_S {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #35414D;
  margin-left: 10px;
}

/* Dashboard Page css */

/* Header Navbar css */

.navbar-container {
  padding: 5px 10px;
  display: flex;
  background-color: #FFFFFF;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #EEF2F5;
  z-index: 9;
}

.Header_Dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
}

.web-display-none {
  display: none
}

.navbar-title {
  color: #3377FF;
  font-size: 23px;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: 'ubuntu';
  width: 8%;
}

.cursor-hover:hover {
  cursor: pointer;
}

.dropdown-profile {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.mob-display-none {
  display: contents;
}

.Display_Center {
display: flex;
justify-content: space-between;
align-items: center;
}

.dropdown-profile-hover:hover {
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 50%;
}

.profile-dropdown-container {
  position: fixed;
  right: 7.5%;
  width: 200px;
  z-index: 9999999999;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 16%);
  top: 54px;
}

.dropdown-profile-title {
  font-size: 16px;
  font-weight: 700;
  padding: 3%;
  text-align: center;
  border-bottom: 1px solid #838383;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Inter", sans-serif;
}

.Class_section {
  font-size: 12px;
  color: rgb(143, 156, 170);
}

.M_Right {
  margin-right: 15px;
  display: flex;
  align-items: center;
}


.dropdown-profile-sub-title-name {
  padding-left: 3%;
  font-weight: 400;
}

.dropdown-profile-sub-title-name {
  padding-left: 3%;
  font-weight: 400;
}



.dropdown-profile-sub-title-name {
  padding-left: 3%;
  font-weight: 400;
}

.School_Tooltip {
  max-width: 250px;
  text-align: center;
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction: column;
  z-index: 99999999999;
  top: 55px;
}

.School_Tooltip_Cube {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}


.Dash_Icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.pswd_toggle {
  position: relative;
  float: right;
  margin-top: -55px;
  margin-right: 39px;
  /* z-index: 999999; */
  color: #3377ff;
  cursor: pointer;
}

.Book_Icon {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 0px !important;
  gap: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #8F9CAA;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: auto !important;
}

.login_alert_modal {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 25px;
  background-color: #fff;
}
.login_alert_txt {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000;
}

.Video_Call_Interface {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 25px;
  background-color: #fff;
}

.D_Flex_Chapter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.h5_Eng {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #1B252F;
}

.P_Unit_S {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #66747F;
}

.Session_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3377FF;
}

.clarify_dot {
  height: 7px;
  width: 7px;
  background-color: #B8C2CC;
  border-radius: 50%;
  display: inline-block;
  transform: rotate(-0.19deg);
}

.D_Flex_Div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.Mon_Apr {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #1B252F;
}


.D_Flex_Time {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid #E6ECF1;
  padding-bottom: 15px;
  margin-top: 10px;
}

.D_Flex_M_Profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0px 0px 0px;
}

.Meet_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
}

.Meet_Time_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #35414D;
}

.Meet_Manoj_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #35414D;
}

.Meet_E_T_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ADB9C7;
}

.Img_Gap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 48px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 30px;
  border: none;
}

.meeting_avail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 48px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 30px;
  border: none;
}

.meeting_disable {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 400px;
  height: 48px;
  background: rgb(204, 204, 204);
  border-radius: 30px;
  margin-top: 30px;
  border: none;
}

.Button_J_M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.Button_Center {
  display: flex;
  justify-content: center;
}
.Button_End {
  display: flex;
  justify-content: end;
}
.Login_alert_btn {
  width: max-content;
  padding: 10px 20px;
}

/* Header Navbar css */

/* notieboard jai start */

.noticecardjai::-webkit-scrollbar {
  display: none;
}

.classNotice {
  height: 580px;
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow: auto;
  margin-top: 2px;
  padding: 15px 25px;
}

.maritopGin {
  margin-top: 0px;
}

.filteryuio {
  top: 20%;
  left: 68%;
}

.maritopGinq {
  margin-top: 18px;
}

.classNotice::-webkit-scrollbar {
  display: none;
}

.classNotice {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.classPadding {
  padding: 20px 0px;
}


.padAbsent17 {
  padding-left: 40px;
}


.padlef17 {
  padding-left: 5px;
}

.noticecardjai {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.datenotice {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #66747F;
  gap: -50px;
}

.datebelownotice {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #1B252F;
}

/* notieboard jai end */


/* attendnece jai :start */

.myattendcard {
  height: 380px !important;
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 15px 25px;
}

.myattendcardteach {
  height: 780px !important;
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 15px 15px;
  margin: 10px 10px;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.myattendcardteach::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.myattendcardteach {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.marfgijn {
  padding-right: 15px !important;
}

.myattendcard::-webkit-scrollbar {
  display: none;
}

.myattendcard {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.attnheig {
  height: 459px;
}

.attenpara {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ADB9C7;
}

.attennumber {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #1B252F;

}


.paraNumbwer {
  padding-left: 48px;
}

.attenpadapada {
  padding-left: 15px;
}

.dateaten {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #66747F;
  padding-top: 10px !important;
  padding-left: 7px !important;
}

.green-bullet-sm {
  color: #06C270;
  font-size: 20px;
  margin-top: -5px;
}

.orange-bullet-sm {
  color: #FC5F37;
  font-size: 20px;
  margin-top: -5px;
}

.mcqmyProgress {
  width: 30% !important;
  background-color: #E5E5E5 !important;
  border-radius: 0px 0px 30px 30px !important;
}

.mcqmyBar {
  height: 20px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%) !important;
}

.barhori {
  transform: rotate(90deg) !important;
  margin-top: 500px;
}

.barboriee1 {
  width: 20px;
  height: 301px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee2 {
  width: 20px;
  height: 251px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee3 {
  width: 20px;
  height: 286px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  border-radius: 30px 30px 0px 0px;
}

.barboriee4 {
  width: 20px;
  height: 338px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee5 {
  width: 20px;
  height: 301px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee6 {
  width: 20px;
  height: 323px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee7 {
  width: 20px;
  height: 294px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee8 {
  width: 20px;
  height: 270px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee9 {
  width: 20px;
  height: 230px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee10 {
  width: 20px;
  height: 230px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee11 {
  width: 20px;
  height: 230px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.janatten {
  width: 25px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: #8F9CAA;
}

.febatten {
  width: 25px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.maratten {
  width: 26px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.apratten {
  width: 24px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.mayatten {
  width: 29px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.junatten {
  width: 25px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.julatten {
  width: 20px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;

}

.augnatten {
  width: 27px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.sepatten {
  width: 26px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.octatten {
  width: 24px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.novatten {
  width: 27px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.decatten {
  width: 27px;
  height: 51px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

/* attendnece jai end */


/* slide start jai */


.css-hn784z button {
  background-color: black;
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  opacity: 0.4;
}

.css-1abc02a button {
  background-color: black;
  -webkit-filter: brightness(120%);
  filter: brightness(120%);
  opacity: 0.4;
}

.css-1m9128y {
  position: absolute;
  top: 87%;
  left: 40%;
  width: 100%;
  z-index: 2222;
}

.padFirsLef {
  padding-left: 0px !important;
}

.padFirsLef2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.paraimg_fir {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #aed6ff;
  position: absolute;
  top: 215px;
  left: 20px;
  z-index: 999;

}

.paraimg_sec {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  color: #FFFFFF;
  position: absolute;
  top: 240px;
  left: 20px;
  z-index: 999;

}

.paraimg_thi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #FFD426;
  position: absolute;
  top: 270px;
  left: 20px;
  z-index: 999;
}

.examinmghj {
  width: 100%;
  height: 345px;
  min-height: 345px;
}


.slidecarosoul {
  border-radius: 5px !important;
  overflow: hidden;
}

/* slide end here jai */



/* Assigments start here */

.cardassignmnetsss {
  box-sizing: border-box;
  height: 612px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 0px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cardassignmnetsss::-webkit-scrollbar {
  display: none;
}

.overfloADDIGNTEACH {
  height: 545px;
  overflow: auto;
}


.overfloADDIGNTEACH::-webkit-scrollbar {
  display: none;
}

.overfloADDIGNTEACH {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mathsassign {
  width: auto;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  border-radius: 5px;
  padding: 10px;
}

.Class_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #3377FF;
  cursor: pointer;
}

.Class_Font_1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #8F9CAA;
  cursor: pointer;
}

.Unit_Font_1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  color: #3377FF;
}

.Unit_Font_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  color: #8F9CAA;
}


.mathsassign1 {
  width: 66px;
  height: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #9B36EA;
  background: #F5EAFD;
  border-radius: 5px;
  padding-top: 7px;
  margin-left: 10px;
  text-align: center;
}

.mathsassign2 {
  width: 69px;
  height: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #FC5F37;
  background: #FFEEEA;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 7px;
  margin-left: 10px;

}

.mathsassign3 {
  width: 65px;
  height: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #3377FF;
  background: #E3EDFF;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 7px;
  margin-left: 10px;
}

.mathsassign4 {
  width: 84px;
  height: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #F68E13;
  background: #FFF6D3;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 7px;
  margin-left: 10px;
}

.assign_date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #66747F;
}

.kildate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #1B252F;
}

.maritb {
  margin: 22px 0px;
}

/* Assignments end here */

/* exam start here */

.engdateexa {
  width: 74px;
  height: 74px;
  background: #EEF2F5;
  opacity: 0.7;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
}

.engdatefirst {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: #1B252F;
}

.engdatesec {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #66747F;
}

.Border_Bottom {
  border-bottom: 1px solid #E6ECF1;
  width: 100%;
}

.engsub {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #1B252F;
}

.engsubdown {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #ADB9C7;
  margin-top: 5px;
}

.examcard {
  box-sizing: border-box;
  height: 612px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 35px 25px;
}

.examcard::-webkit-scrollbar {
  display: none;
}

.examcard {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* exam end here */

/* Dashboard Page css */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* achivements */



.stylemyCarsou {
  height: 334px;
  width: 240px;
  padding-top: 20px;
  text-align: center;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-family: sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 10px;
  box-sizing: border-box;
}

.lementstyle {
  position: relative;
  height: 450px;
  width: 100%;
  display: flex;
  background: rgb(227, 237, 255);
  border-radius: 5px;
  flex: 1 1 0%;
}

.achiveCard {
  height: 612px;
}

.achiveimg {
  border-radius: 50%;
}

.titleimg {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  color: #1B252F;
  text-transform: capitalize;
}

.pricelisty {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #66747F;
  text-transform: capitalize;
}

/* achivements */

/* Event */
.add_event {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #3377FF;
  margin-bottom: 7px;
  margin-right: 15px;
}

.eventcard {
  box-sizing: border-box;
  height: 550px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  overflow-y: scroll;
  padding: 10px 0px;
}

.eventcard::-webkit-scrollbar {
  display: none;
}

.eventcard {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.titleevent {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  color: #1B252F;
}

.English_Time2 {
  width: 94%;
  height: auto;
  border: 1px solid #EEF2F5;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px 1px 1px 5px;
  padding: 8px 15px 0px 15px;
  margin-left: 2%;
  margin-top: 5px;
}

.Tilelelele {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #1B252F;
}

/* Event */

/* exam result */
.ex_recard {
  box-sizing: border-box;
  height: 550px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 30px 20px;
}

.MariOExResul {
  margin-top: 40px;
}


.totex_re {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ADB9C7;
}

.totuni_re {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #4C88FF;
}

.theeigex_re {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #1B252F;
}

.fivex_re {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #1B252F;
}

.lanex_re {
  width: 49px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #252525;
}

.coverthreejai444 {
  height: 8px;
  border-radius: 50px;
  background-image:
  linear-gradient(to right, #fc4a1a, #f7b733);
}

.numex_re {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  margin-left: 20px;
  margin-top: -3px;
  color: #1B252F;
}


.coverflexonejai {
  width: 50%;
  background-color: #E5E5E5;
  border-radius: 50px;
  height: 8px;
  margin-left: 35px;
  margin-top: 6px;
}

.coverthreejai111 {
  height: 8px;
  border-radius: 50px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
}

.barex_re {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.QuestionType_Options_list {
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 10px;
  z-index: 99999999;
  overflow: auto;
  width: 30% !important;
  height: auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  right: 0px;
  top: 112px;
}

.dropdown-profile-sub-title {
  font-size: 14px;
  padding: 2%;
  display: flex;
  flex-direction: row;
  padding-left: 10%;
  padding-top: 3%;
  padding-bottom: 3%;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-profile-sub-title:hover {
  background-color: #71A0FF;
  color: #fff;
  cursor: pointer;
}

.dropdown-profile-sub-title-name {
  padding-left: 3%;
  font-weight: 400;
}

/* exam result */

/* birthday */

.birthCard {
  height: 520px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 30px;
}

.ovescol::-webkit-scrollbar {
  display: none;
}

.ovescol {
  overflow-y: scroll;
}

.ovescol {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.imgbirthCake {
  width: 120px;
  margin-top: -30px;
  background: url(../../Assets/cakback.png);
}

.Textbirth {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.imhtext {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #1B252F;
}

.imhtext2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8F9CAA;
}

.wishbith {
  gap: 10px;
  color: #fff;
  height: 34px;
  background: #3377FF;
  border-radius: 30px;
  margin-top: 5px;
  padding: 4px 20px 0px 20px;
}

.gapimh {
  display: flex;
  align-items: center;
  gap: 15px;
}

.ipmics_img {
  vertical-align: middle;
  width: 45px;
  border-radius: 50px;
  height: 45px;
}

.wishbith2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  margin-top: 12px;
  color: #1B252F;
}

/* birthday */

/* footer */

.footpower {
  width: 68px;
  height: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #8F9CAA;
}

.footyou {
  width: 81px;
  height: 16px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #3377FF;
}

.allfoot {
  width: 149px;
  height: 18px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #ADB9C7;
  padding-top: 10px;
}

.paddPower {
  padding-top: 5px;
}

.smacfoot {
  width: 280px;
  height: 40px;
  background: #2E3A53;
  box-shadow: 0px -4px 15px rgba(65, 68, 73, 0.18);
  border-radius: 8px 8px 0px 0px;
  z-index: 9999999;
}

.smacctextfoot {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  padding-left: 10px;
}

.bootbord {
  border: 1px solid #35414D !important;
}

/* footer */

/* Timetable design css Start here */

.Switch_Day_Week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 172px;
  height: 38px;
  background-color: #FAFCFF;
  border: 1px solid #E3EDFF;
  border-radius: 30px;
  cursor: pointer;
}

.Switch_Day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  border-radius: 30px;
}

.Switch_Week {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  background: #4C88FF;
  border-radius: 30px;
}

.Switch_Week_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
}

.Class_Period {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Flex_Row_Period {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 25px 0px 0px;
}

.Period_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #B8C2CC;
}

.Period_Time {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #ADB9C7;
}

.D_D_M {
  display: flex;
  flex-direction: column;
}

.Day_Month {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ADB9C7;
  width: 44px;
}

.Eng_Video {
  width: 12%;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #9B36EA;
  border-left-width: 2px;
  padding: 10px;
}

.Display_Flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.E_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #9B36EA;
}

.Unit_Sup {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2A004A;
}

.Unit_Supp {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2A004A;
}

.Unit_Color_sup {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #66747F;
}

.Unit_Color_supp {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #66747F;
}

.Mat_Video {
  width: 10%;
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #06C270;
  border-left-width: 2px;
  padding: 10px;
}

.S_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #1B252F;
}

.Phy_Video {
  width: 10%;
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #FC5F37;
  border-left-width: 2px;
  padding: 10px;
}

.Bot_Video {
  width: 10%;
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #3377FF;
  border-left-width: 2px;
  padding: 10px;
}

.Zoo_Video {
  width: 10%;
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #3377FF;
  border-left-width: 2px;
  padding: 10px;
}

.Tam_Video {
  width: 10%;
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #FF3E84;
  border-left-width: 2px;
  padding: 10px;
}

.Che_Video {
  width: 10%;
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #F78E13;
  border-left-width: 2px;
  padding: 10px;
}

.Mat_Video {
  width: 10%;
  background-color: #FFFFFF;
  border: 1px solid #EEF2F5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border-left-color: #06C270;
  border-left-width: 2px;
  padding: 10px;
}

.Switch_Day_Blue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  background: #4C88FF;
  border-radius: 30px;
}

.Switch_Week_White {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  gap: 10px;
  width: 82px;
  height: 30px;
  border-radius: 30px;
}

.Switch_Week_Font_Timetable {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #66747F;
}

.Switch_Day_Font_Timetable {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
}

.Dash_home_QWI {
  margin-top: 100px;
}

.M_D_W {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

.B_Bottom {
  border-bottom: 1px solid #D6DDE6;
  width: 100%;
}

.Sub_Flex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Row_Reverse {
  display: flex;
  flex-direction: row;
  align-Items: center;
  gap: 10px;
}

.Video_Icon_Size {
  font-size: 25px;
}

.F_Gap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

.Padding_Time {
  padding: 25px 25px 0px 25px;
}

.F_J_C {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
}

.Web_Display_None {
  display: none;
}

.Col_Gap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.Pad_Px {
  padding: 15px 15px 10px 15px;
}

.Web_Cama_display_None {
  display: none;
}

.Switch_Day_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #66747F;
}

/* Timetable design css End here */



/* Teacher Dashboard Css */

.Today_Date_TD {
  font-weight:600;
  font-size:18px;
}

.Subject-Timing_TD {
  background-color: #F4F4F4;
  border-radius: 15px;
  padding: 15px;
}

.D-ES-Card_TD {
  box-sizing: border-box;
  left: 0px;
  top: 0px;
  border-radius: 25px;
  border: 1px solid #591B08;
  background: linear-gradient(90deg, #614385 0%, #516395 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Exam-Schedule_TD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background: #7A699D;
  border-radius: 25px 0px;
}

.Exam_Month_TD {
  font-size: 18px;
  color: white;
}

.Padd2101_TD {
  padding: 10px 10px 0px 10px;
}

.P_15_20_10_20_TD {
  padding: 15px 20px 10px 20px;
  border-bottom: 1px solid #CBCBCB;
}

.Attend_Scroll_TD {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 30px;
}

.Class_Att_Scroll_TD {
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 61px;
}

.Class_Att_Scroll_TD::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.ProgressBarColor_TD {
  width: 65%;
  background-color: #E5E5E5;
  border-radius: 50px;
  height: 12px;
  margin-top: 2px;
}

.ProgressBarColorIn_TD {
  height: 12px;
  border-radius: 50px;
}

.ProgressBarColor_ER_TD {
  width: 100%;
  background-color: #E5E5E5;
  border-radius: 50px;
  height: 12px;
  margin-top: 2px;
}

.ProgressBarColorIn_ER_TD {
  height: 12px;
  border-radius: 50px;
}

.ProgressBarColor_CA_TD {
  width: 84%;
  background-color: #E5E5E5;
  border-radius: 50px;
  height: 12px;
  margin-top: 2px;
}

.ProNumber_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17.9721px;
  color: #252525;
}

.PercentNumber_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17.9721px;
  color: #BABABA;
}

.CA_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}

.OuterDiv2_TD {
  box-sizing: border-box;
  height: 300px;
  left: 0px;
  top: 0px;
  border-radius: 25.663px;
  background: linear-gradient(90deg, #000C40 0%, #607D8B 100%);
}

.Diary_TD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background: #37476A;
  border-radius: 25px 0px;
}

.OuterDiv3_TD {
  box-sizing: border-box;
  height: 300px;
  left: 0px;
  top: 0px;
  border-radius: 25.674px;
  border: 1.007px solid #012A44;
  background: linear-gradient(226deg, #003B61 0%, #115886 100%);
}

.WorkingDiv2_TD {
  box-sizing: border-box;
  padding: 15px 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.08);
  border: 0.6px solid rgba(255, 255, 255, 0.40);
}

.Subject-Border_TD {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  gap: 12.75px;
  border-radius: 17.75px;
  border: 1px solid #002238;
  background: rgba(0, 34, 56, 0.50);
}

.Assignment_TD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  border-radius: 25.663px 0px;
  background: #033351;
}

.Assignment_Viewmore_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15.3977px;
  text-align: right;
  color: white;
  cursor: pointer;
}

.Date-Font-Color_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #FFF;
}

.Subject-Topic_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #FFF;
}

.OuterDiv4_TD {
  box-sizing: border-box;
  height: 550px;
  border-radius: 25px;
  background: linear-gradient(135deg, #0E7933 0%, #1CB050 100%);
}

.OuterCard-Info_TD {
  height: 420px;
  background: #FFFFFF;
  box-shadow: 5px 10px 10px 7px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.OuterCard-Info_TD::-webkit-scrollbar {
  display: none;
}

.PaddClassPoster_TD {
  padding: 20px 35px 10px 35px;
}

.Class_CP_TD {
  display: inline-flex;
  padding: 5px 15px;
  align-items: flex-start;
  border-radius: 20px;
  background: #FFF;
  cursor: pointer;
}

.Class_CP_Font_TD {
  color: #108037;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.Class_Section_TD {
  display: inline-flex;
  padding: 5px 15px;
  align-items: flex-start;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.30);
  background: rgba(0, 40, 14, 0.25);
  cursor: pointer;
}

.Class_Section_Font_TD {
  color: #ECF7FF;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.OuterDiv6_TD {
  box-sizing: border-box;
  height: 550px;
  background: #FFFFFF;
  border: 1.00662px solid #BABABA;
  border-radius: 25.6744px;
}

.Padd_School_Poster_TD {
  padding: 25px 35px;
}

.OuterDiv5_TD {
  box-sizing: border-box;
  height: 550px;
  border-radius: 25px;
  background: linear-gradient(135deg, #0E7933 0%, #1CB050 100%);
}

.HeightCScroll_TD {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 450px;
}

.HeightCScroll_TD::-webkit-scrollbar{
  display: none;
}

.OuterDiv8_TD {
  box-sizing: border-box;
  height: 340px;
  border-radius: 25.674px;
  border: 1.007px solid #584100;
  background: linear-gradient(226deg, #E5A100 0%, #D4A32F 100%);
}

.Birthday_TD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  border-radius: 25.674px 0px;
  background: #714F00;
}

.WishesOuter_TD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 12.837px;
  border: 0.899px solid rgba(94, 65, 9, 0.60);
  background: rgba(113, 80, 2, 0.54);
  cursor: pointer;
}

.UpcomBirth_TD {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 9.08px;
  width: -moz-fit-content;
  width: fit-content;
  background: #9F7411;
  border: 0.898605px solid #78550C;
  border-radius: 12px;
  cursor: pointer;
}

.Dropdown_ER_TD {
  display: inline-flex;
  height: 28px;
  padding: 2px 10px; 
  align-items: center;
  border-radius: 22.5px;
  border: 0.6px solid #3377FE;
  background: rgba(43, 108, 239, 0.10);
  color: #1855CC;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.Sub_Phy_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.EX_Padd_TD {
  padding: 20px;
  border-bottom: 1px solid #BABABA;
} 

.EX_Padd_1_TD {
  padding: 20px;
} 

.ER_Pass_TD {
  color: #07A571;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.Font_70_TD {
  color: #07A571;
  font-family: 'Inter';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.Span_Pass_TD {
  color: #07A571;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.ER_Fail_TD {
  color: #FD3A20;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.Font_30_TD {
  color: #FD3A20;
  font-family: 'Inter';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.Span_Fail_TD {
  color: #FD3A20;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.ER_Total_TD {
  color: #3073F9;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.Font_100_TD {
  color: #3073F9;
  font-family: 'Inter';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.Span_Total_TD {
  color: #3073F9;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.ExamResult_Height_TD {
  height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ExamResult_Height_TD::-webkit-scrollbar {
  display: none;
}

.Class_Font_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.Class_Font_1_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.Class_Font_2_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.Padding_25_15_TD {
  padding: 25px 15px 30px 15px;
}

.Piechart_Width {
  width:200px;
}

.Exam_Result_VM_TD {
  padding: 15px;
  display: flex;
}

.Piechart_TD {
  padding: 25px;
  height: 515px;
  border-radius: 25px;
  border: 1px solid #BABABA;
  background: #F4F4F4;
}

.Mon_Ex_Font_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.Pie_Font_Sub_TD {
  color: #4F4F4F;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.Grade_List_Font_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.Grade_Height_TD {
  height: 415px;
  overflow-y: scroll;
}

.Grade_Height_TD::-webkit-scrollbar {
  display: none;
}

.AssignmentDiv_1_TD {
  box-sizing: border-box;
  height: auto;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(116, 116, 116, 0.50);
  background: rgba(116, 116, 116, 0.10);
}

.AssignmentDiv_2_TD {
  box-sizing: border-box;
  height: auto;
  border-radius: 20px;
  border: 1px solid rgba(3, 51, 81, 0.50);
  background: rgba(3, 51, 81, 0.10);
  padding: 20px;
}

.AssignmentDiv_3_TD {
  box-sizing: border-box;
  height: auto;
  border-radius: 20px;
  border: 1px solid rgba(7, 165, 113, 0.50);
  background: rgba(7, 165, 113, 0.10);
  padding: 20px;
}

.OuterSemiDiv1_TD {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.OuterSemiDiv1_TD::-webkit-scrollbar {
  display: none;
}

.OuterSemiDiv2_TD {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.OuterSemiDiv2_TD::-webkit-scrollbar {
  display: none;
}

.OuterSemiDiv3_TD {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.OuterSemiDiv3_TD::-webkit-scrollbar {
  display: none;
}

.PCP_Assignment_TD {
  display:flex;
  padding: 20px 20px 0px 60px;
  gap:40px;
  border-bottom: 1px solid #BABABA;
}

.creamNumviewmore_TD {
  display: inline-flex;
  padding: 3px 18px;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  border: 0.6px solid #3377FE;
  background: #3377FE;
  cursor: pointer;
}

.creamNumviewmoreFont_TD {
  color: #FFF;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.nameAchivevi_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #252525;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.nameAchivevi_colorbluesih_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #3377FE;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  border-bottom: 3px solid #3377FE;
  padding-bottom: 3px;
}

.SubjectFontSub_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #252525;
}

.postdateviewmore_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #252525;
}

.Submit_Student_TD {
  color: #24775C;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
}

.Submit_Post_TD {
  color: #24775C;
  text-align: right;
  font-family: 'Inter';
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}

.Submit_post_Normal_TD {
  color: #252525;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.AttendViHead_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: white;
  display: flex;
  justify-content: center;
}

.AttendViHead_Div_TD {
  border-radius: 26px 26px 0px 0px;
  background: #676767;
  padding: 20px;
}

.ClassendarVi_TD {
  padding: 0px 20px;
}


.Attend_Month_TD {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 25px;
  border-bottom: 1px solid #BABABA;
  padding-bottom: 20px;
}

.viewAttend1_TD {
  height: 570px;
  background: #F4F4F4;
  border: 1px solid #BABABA;
  border-radius: 25.6628px;
  padding: 0px 0px 20px 0px;
}

.Attend_Padding_TD {
  padding: 0px 20px 20px 20px;
}

.react-calendar {
  margin-top: 15px !important;
}

.AttendanceFlex_TD {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 10px 0px;
}

.viewAttend_TD {
  height: 570px;
  background: #F4F4F4;
  border: 1px solid #BABABA;
  border-radius: 25.6628px;
  overflow: auto;
  padding: 25px; 
}

.viewAttend_TD::-webkit-scrollbar {
  display: none;
}

.OuterSemiDiv3_ES_TD {
  box-sizing: border-box;
  border-radius: 20px;
  padding: 20px;
}

.MTop_TD {
  margin-top: 15px !important;
  margin-bottom: 40px;
}

.WorkingDiv1_TD {
  box-sizing: border-box;
  padding: 15px 15px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.30);
  background: rgba(248, 248, 248, 0.10);
}

.View-More-Font_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #252525;
  cursor: pointer;
}

.View-More-FontColor_TD {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: white;
  cursor: pointer;
}

.MTop_Assignment_TD {
  margin-top: 20px !important;
  margin-bottom: 40px;
}

.title-txt-label_TD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background-color: #1652C9;
  border-radius: 25px 0px;
}

.OuterDiv10iao_TD {
  box-sizing: border-box;
  height: auto;
  background: #FFFFFF;
  border: 1.00616px solid #BABABA;
  border-radius: 25px;
  margin-top: 0px;
}

.OuterDiv10_TD {
  box-sizing: border-box;
  height: auto;
  background: #FFFFFF;
  border: 0px solid #BABABA;
  border-radius: 25px;
  margin-top: 0px;
}








/* Teacher Dashboard Css */




/* Responsive design for Timetable css start here (Mobile)*/

@media (min-width:279px) and (max-width:480px) and (orientation:portrait) {

  /* For Day style css  start here */
  .Time_Table {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1B252F;
  }

  .Apr_22 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #66747F;
  }

  .Header_Dashboard {
    display: none;
  }

  .Time_Apr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Switch_Day_Week {
    padding: 0px;
    width: 140px;
    height: 31px;
    border-radius: 30px;
    cursor: pointer;
  }

  .Switch_Day {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 10px;
    width: 82px;
    height: 30px;
    border-radius: 30px;
  }

  .M_D_W {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Day_Date {
    margin: 20px 0px 0px 0px;
    text-align: center;
  }

  .Day {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #ADB9C7;
  }

  .Date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ADB9C7;
  }

  .Tue_Day {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #1B252F;
  }

  .Tue_Date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #3377fe;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 4.5px;
    display: flex;
    align-items: center;
  }

  .B_Bottom {
    border-bottom: none;
  }

  .Time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ADB9C7;
    margin-top: 0px;
  }

  .Eng {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .AM_Time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    margin-left: 10px;
  }

  .Video_Icon_Size {
    font-size: 20px;
  }

  .Unit_supplementarty {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }

  .D_FLex_Unit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Cath_F_S {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #35414D;
    margin-left: 10px;
  }

  .F_Gap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .Padding_Time {
    padding: 25px 10px 0px 10px;
  }

  /* For Day style css end here */


  /* For Week style css start here */
  .M_D_W {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .Flex_Row_Period {
    display: none;
  }

  .Display_Flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .D_D_M {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
    width: max-content;
  }

  .Day_Month {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #1B252F;
    width: max-content;
  }

  .F_J_C {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    gap: 10px;
    width: 100%;
    white-space: nowrap;
    padding: 0px 10px;
    background-color: #FFFFFF;
  }

  .F_J_C::-webkit-scrollbar {
    display: none;
  }

  .F_J_C {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .Eng_Video {
    min-width: 65%;
  }

  .Mat_Video {
    min-width: 65%;
  }

  .Phy_Video {
    min-width: 65%;
  }

  .Bot_Video {
    min-width: 65%;
  }

  .Zoo_Video {
    min-width: 65%;
  }

  .Tam_Video {
    min-width: 65%;
  }

  .Che_Video {
    min-width: 65%;
  }

  .Web_Display_None {
    display: contents;
  }

  .Col_Gap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Pad_Px {
    padding: 25px 0px 10px 0px;
  }

  .Web_Cama_display_None {
    display: contents;
  }

  /* For Week style css start here */

  .Meet_Time_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #35414D;
  }

  .Mon_Apr {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #1B252F;
  }

  .P_Unit_S {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #66747F;
  }

  .Session_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #3377FF;
  }

}

/* Responsive design for Timetable css end here (Mobile)*/



/* Responsive design for Timetable css end here (Tablet)*/

@media (min-width:481px) and (max-width:1200px) and (orientation:landscape) {

  .Header_Dashboard {
    display: none;
  }

  .Flex_Row_Period {
    padding: 0px 20px 0px 40px
  }

  .Unit_Sup {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #2A004A;
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
  }

  .Unit_Color_sup {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Eng_Video {
    width: 11%;
    padding: 5px;
  }

  .Mat_Video {
    width: 11%;
    padding: 5px;
  }

  .Phy_Video {
    width: 11%;
    padding: 5px;
  }

  .Bot_Video {
    width: 11%;
    padding: 5px;
  }

  .Zoo_Video {
    width: 11%;
    padding: 5px;
  }

  .Tam_Video {
    width: 11%;
    padding: 5px;
  }

  .Che_Video {
    width: 11%;
    padding: 5px;
  }

  .E_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #9B36EA;
  }

  .S_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #1B252F;
  }

  .Unit_Color_supp {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #66747F;
  }

  .Col_Gap {
    gap: 8px;
  }
}

/* Responsive design for Timetable css end here (Tablet)*/




/* Attendance main */

.borderboxAtten {
  box-sizing: border-box;
  background-color: #FAFAFA;
  border: 1px solid #EEF2F5;
  border-radius: 8px;
  margin: 20px;
}

.borderbottmobi {
  box-sizing: border-box;
  width: 90%;
  height: 70;
  background-color: #FAFAFA;
  border: 1px solid #EEF2F5;
  border-radius: 8px;
  margin-left: 5%;
  margin-top: 11%;
}

.gapatten {
  padding: 20px;
}

.PROGRESS {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}


.janattenmobi {
  text-align: center;
  width: 25px;
  height: 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 150%;
  color: #8F9CAA;

}


.barboriee1 {
  width: 20px;
  height: 201px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee2 {
  width: 20px;
  height: 151px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee3 {
  width: 20px;
  height: 186px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  border-radius: 30px 30px 0px 0px;
}

.barboriee4 {
  width: 20px;
  height: 238px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee5 {
  width: 20px;
  height: 201px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee6 {
  width: 20px;
  height: 223px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee7 {
  width: 20px;
  height: 194px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee8 {
  width: 20px;
  height: 170px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee9 {
  width: 20px;
  height: 130px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee10 {
  width: 20px;
  height: 130px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee11 {
  width: 20px;
  height: 150px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.barboriee12 {
  width: 20px;
  height: 170px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  opacity: 0.2;
  border-radius: 30px 30px 0px 0px;
}

.attenparamobi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #ADB9C7;
}

.attennumbermobi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #1B252F;
}

.upheight {
  height: 30%;
}

/* Attendance main */

/* dark mode toggle */

.switch {
  position: relative;
  top: 5px;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.fa-moon {
  color: pink;
}

.fa-sun {
  color: yellow;
}

/* dark mode toggle end */


/* Responsive design for Mobile  */

.AM_Timemobi {
  display: none;
}

.schoolScrollMobi {
  display: none;
}

.viewall_class {
  display: none;
}

.classNoticeMobi {
  display: none;
}

.Time_Tablemobi {
  display: none;
}

.digcolumnRownaki {
  display: none;
}

.myattendcardMobi {
  display: none;
}

.calssTitleMobi {
  display: none;
}

.AssigTimeMObi {
  display: none;
}

.exschDahmobi {
  display: none;
}

.cardassignmnetsssMobi {
  display: none;
}

.examcardMobi {
  display: none;
}

.EvntTitle {
  display: none;
}

.MobiExaResu {
  display: none;
}

.flerojubet {
  display: none;
}

.Achive_Title {
  display: none;
}

.birthTitle {
  display: none;
}


.WebShownDesign {
  display: block;
  padding-left: 0px !important;
  padding-right: 0.1px !important;
  position: fixed;
  bottom: 0px;
  z-index: 99999;
}

.Mobile_ShowDesign {
  display: none;
}

.footerdash {
  display: contents;
  margin-top: 140px;
}

.webFoot {
  margin-top: 0px;
  background-color: #EEF2F5;
  height: 40px;
}

.Foot_erer {
  margin-top: 150px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: none;
  outline: none;
}

.headfix {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.MbileShow {
  display: none;
}

/* Responsive design for Mobile  */



/* Style for Bookview List  */


.H_katbook {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 26px;
  color: black;
}

.Card_Book_List {
  margin-top: 90px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px !important;
  border: 1px solid #E6ECF1 !important;
  position: inherit !important;
}


.Booklist_Subject {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #1B252F;
}

.Container {
  display: flex;
  flex-wrap: wrap;

}

.Subject_container {
  padding: 20px 50px;
}

.Book_U_PC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  font-family: 'Inter';
}

.BookOpen_Icon {
  width: 16px;
  height: 14px;
}

.Manoj_span {
  font-size: 12px;
  font-weight: 500;
}

.Margin_Left_Card {
  width: 260px;
  margin: 0px 5px;
}

.web-display-none {
  display: none
}

.Image_Size {
  width: 100%;
  height: 165px;
  margin-top: -55px;
  cursor: pointer;
}

.Portions_covered_Text_Container {
  width: 45%;
}

.Units_Count_Container {
  width: 38%;
}

.Pad_Mar {
  padding: 20px 10px 0px 10px;
  margin-top: 16px;
}

.Image_Size:hover {
  box-shadow: 1px 2px 22px 3px #dadad9;
}

.myProgress {
  width: 80% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
  float: left;
}

.myBar {
  height: 10px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%) !important;
}


.grennmyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.grennmyBar {
  height: 10px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #13DA84 0%, #189C63 100%);
}


.orangemyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.orangemyBar {
  height: 10px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
}

.purplemyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.purplemyBar {
  height: 10px !important;
  border-radius: 50px !important;
  background: linear-gradient(180deg, #C780FF 0%, #8B3DC9 100%);
}

.pinkmyProgress {
  width: 100% !important;
  background-color: #E5E5E5 !important;
  border-radius: 50px !important;
}

.pinkmyBar {
  height: 10px !important;
  border-radius: 50px;
  background: linear-gradient(180deg, #FF7AAA 0%, #E62168 100%);
}

.Subject_container::-webkit-scrollbar {
  display: none;
}

.Subject_container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.myprogress_pertg {
  font-size: 13px;
  font-family: 'Inter';
  padding-top: 5px;
}

.myprogress_pertg span {
  margin-left: 6%;
}

.Percent_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #35414D;
}

/* Style for Bookview List  */





/* Style  for    K_a_m_p_u_s    N_e_w_D_a_s_h_b_o_a_r_d      */


.DTC-Card {
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1.00616px solid #CBCBCB;
  border-radius: 25px;
}

.Today-Class {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background: linear-gradient(270deg, #3174FA -5.52%, #134EC3 106.17%);
  border-radius: 25px 0px;
}

.Weekly-Class {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 8px 18px;
  background: linear-gradient(270deg, #3174FA -5.52%, #134EC3 106.17%);
  border-radius: 10px;
  cursor: pointer;
}

.Weekly-Font {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}



/* (Rajesh) Style for Table */

.bodyborder{
  border: 1px solid #bdbdbd;
  border-radius: 25px;
  background-color: white; 
}

.tableborderstyle {
  border: 1px solid #E1e1e1;
  background-color: #f2f2f2;
  margin: 0px;
  border-radius: 16px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background: linear-gradient(270deg, #3174FA -5.52%, #134EC3 106.17%);
  border-radius: 25px 0px;
}

.tableborder {
  background-color: rgb(242, 242, 242);
  border: 1px solid #bdbdbd;
  margin: 0px -12px;
  border-radius: 12px;
  padding: 0px 10px;
  z-index: -9999 !important;
}

.bodyborder.container {
  overflow: hidden;
}

.titletext {
  color: #1653cc;
  font-size: 18px;
  font-weight: 600;
  padding-top: -15px;
  border-bottom: 2px solid #bdbdbd;
  border-right: 2px dashed #bdbdbd; 
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  border: 1px solid #dddddd;
  text-align: center;
  padding:8px; 
}

.boxtab{
  background-color: #baccf9;
  font-size: 12px;
  color: #000000;
  text-align: left;
  border-radius: 10px;
  padding:10px; 
}

.boxtitle{
  font-size: 14px;
  font-weight:600;
  cursor: pointer;
}

.boxsec{
  font-size: 12px;
  font-weight:600;
  cursor: pointer;
}

th {
  border: 1px solid #dddddd;
  text-align: center !important;

}
.tableheadDay{
 color: #1653cc;
  font-size: 16px;
}

.tablehead{
padding-top: 10px;
font-size: 16px;
color: #1653cc;
}

.tabletime{
color:#413f3f;
font-size: 12px;
}

.days{
  font-size: 16px;
  color:#009f6b;
  font-weight: 600;
}

tr{
border-bottom: 2px dashed #e1e1e1 !important;
}

td,th{
  border-right: 2px dashed #e1e1e1 !important;
}

/* (Rajesh) Style for Table */

.Today-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 14px;
  color: #FFFFFF;
}

.Date-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15.3977px;
  line-height: 12px;
  color: #000000;
}

.View-More-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15.3977px;
  line-height: 12px;
  text-align: right;
  color: #000000;
  cursor: pointer;
}

.View-More-FontColor {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15.3977px;
  line-height: 12px;
  text-align: right;
  color: white;
  cursor: pointer;
}

.View-More-FontColor1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15.3977px;
  line-height: 12px;
  text-align: right;
  color: black;
  cursor: pointer;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-right-0 {
  padding-right: 0px;
}

.D-FlexDV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.Subject-Timing {
  background-color: #F4F4F4;
  border-radius: 15px;
  padding: 25px 15px;
}

.Subject-Unit {
  border-radius: 15px;
  border: 1px solid white;
  background: rgba(255, 255, 255, 0.08);
}

.E-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  color: #252525;
}

.T-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #4F4F4F;
}

.Unit-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #252525;
}

.D-ES-Card {
  box-sizing: border-box;
  left: 0px;
  top: 0px;
  background: linear-gradient(263.42deg, #366EDE 0.53%, #417DF3 100.02%);
  border-radius: 25px;
}

.title-txt-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background-color: #1652C9;
  border-radius: 25px 0px;
}

.Exam-Schedule {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background-color: #1652C9;
  border-radius: 25px 0px;
}

.Exam-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 12px;
  color: #FFFFFF;
}

.NT-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #FFFFFF;
}

.Month-font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #FFFFFF;
}

.E-Font1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}

.Time-font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #FFFFFF;
}

.Unit-Name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.MainDiv {
  display: flex;
  align-items: center;
  gap: 15px;
}

.InnerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 17px;
}

.D_A_M-Css {
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  padding: 5px 18px;
  border-radius: 15px;
  text-align: center;
}

.OuterDiv1 {
  box-sizing: border-box;
  height: 300px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  border-radius: 25px;
}

.OuterDiv2 {
  box-sizing: border-box;
  height: 300px;
  left: 0px;
  top: 0px;
  background: linear-gradient(115.83deg, #366EDE 3.8%, #417DF3 98.11%);
  border-radius: 25.6628px;
}

.OuterDiv3 {
  box-sizing: border-box;
  height: 300px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  border: 1.00662px solid #CBCBCB;
  border-radius: 25.6744px;
}

.WorkingDiv {
  box-sizing: border-box;
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
  border-radius: 10px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.PreFontColor {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3EAD89;
}

.PreFontSize {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #05A26F;
}

.PreFontColor1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #FF6A6A;
}

.PreFontSize1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #FF6A6A;
}

.PreFontColor2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #538CFF;
}

.PreFontSize2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #538CFF;
}

.DiaryTimeDiv {
  box-sizing: border-box;
  background-color: rgba(248, 248, 248, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 15px 25px;
}

.WorkingDiv1 {
  box-sizing: border-box;
  background: rgba(248, 248, 248, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 15px 15px;
}

.TimeFontCol {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
}

.TimeFontCol1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #FFFFFF;
}

.Exam-Schedule1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background-color: #1652C9;
  border-radius: 25px 0px;
}

.WorkingDiv2 {
  box-sizing: border-box;
  background-color: #F4F4F4;
  border: 0.6px solid #F4F4F4;
  border-radius: 10px;
  padding: 15px 15px;
}

.Subject-Border {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  gap: 12.75px;
  background: #1C5AD5;
  border: 1px solid #1C5AD5;
  border-radius: 17px;
}

.Subject-Border-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
}

.Date-Font-Color {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #252525;
}

.Subject-Topic {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #252525;
}

.Ex-Schedule-Height {
  padding: 25px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 80%;
}

.Ex-Schedule-Height::-webkit-scrollbar {
  display: none;
}

.HeightCScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 460px;
}

.HeightCScroll::-webkit-scrollbar {
  display: none;
}


.OuterDiv4 {
  box-sizing: border-box;
  height: auto;
  background: linear-gradient(142.28deg, #366EDE 0.72%, #417DF3 100%);
  border-radius: 15px;
}

.OuterDiv5 {
  box-sizing: border-box;
  height: auto;
  background: linear-gradient(142.28deg, #366EDE 0.72%, #417DF3 100%);
  border-radius: 15px;
}

.OuterDiv6 {
  box-sizing: border-box;
  height: auto;
  background: #FFFFFF;
  border: 1.00662px solid #BABABA;
  border-radius: 25.6744px;
}

.ClassPosterFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}

.OuterCard-Info {
  height: auto;
  background: #FFFFFF;
  box-shadow: 5px 10px 10px 7px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
}

.OuterCard-Info2 {
  background: #FFFFFF;
  box-shadow: 5px 10px 10px 7px rgba(0, 0, 0, 0.2);
  border-radius: 13px;
}

.Off-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #252525;
}

.Mail-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #434343;
}

.Content-Font {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #252525;
}

.SportsFont {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  background: linear-gradient(89.57deg, #0E7B35 15.37%, #1AA34A 68.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.Class_Poster_Css {
  width: 100%;
  height: 250px;
}

.Month-Exam-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4F4F4F;
}

.DropAlignDesign {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 5px 3px 10px;
  gap: 16px;
  height: 30px;
  background: #D8E2F6;
  border: 0.6px solid #3377FE;
  border-radius: 15px;
  font-family: 'Inter';
  font-style: normal;
  color: #1855CC;
  font-weight: 600;
}

.DropAlignDesignFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1855CC;
  background: white;
}


.TotalCount-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15.4047px;
  color: #4F4F4F;
}

.CountNumber-Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #252525;
}

.CountNumber-Font1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #4F4F4F;
}

.ProgressBarColor {
  width: 50%;
  background-color: #E5E5E5;
  border-radius: 50px;
  height: 12px;
  margin-left: 35px;
  margin-top: 2px;
}

.ProgressBarColorIn {
  height: 12px;
  border-radius: 50px;
}

.OuterDiv7 {
  box-sizing: border-box;
  height: 340px;
  background: #FFFFFF;
  border: 1.00616px solid #BABABA;
  border-radius: 25px;
}

.OuterDiv8 {
  box-sizing: border-box;
  height: 340px;
  background: linear-gradient(249.3deg, #366EDE 3.15%, #417DF3 96.69%);
  border-radius: 25px;
}

.ProNumber {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17.9721px;
  color: #252525;
}

.PercentNumber {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17.9721px;
  color: #BABABA;
}

.GradeCard {
  width: 220px;
  height: 240px;
  background: #FFFFFF;
  box-shadow: 5.82314px 11.6463px 17.4694px 3.49389px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  padding: 20px;
}

.GradeCard1 {
  width: 220px;
  height: 240px;
  background: #FFFFFF;
  box-shadow: 5.82314px 11.6463px 17.4694px 3.49389px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  padding: 20px;
}

.GradeCard2 {
  width: 220px;
  height: 240px;
  background: #FFFFFF;
  box-shadow: 5.82314px 11.6463px 17.4694px 3.49389px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  padding: 20px;
}

.GradeCard3 {
  width: 220px;
  height: 240px;
  background: #FFFFFF;
  box-shadow: 5.82314px 11.6463px 17.4694px 3.49389px rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  padding: 20px;
}

.Name_Font_St {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #252525;
}

.Class_Font_Sec {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #4F4F4F;
}

.SportsCompFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #4F4F4F;
}

.BirthdayColor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 15px 20px;
  left: 0px;
  background-color: #714F00;
  border-radius: 25px 0px;
}

.OuterDiv9 {
  height: 220px;
  left: 0px;
  top: 0px;
  background: rgba(244, 244, 244, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 15.4047px;
}

.Today-Font-White {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
}

.LilFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
}

.LilClassFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14.1209px;
  color: #FFFFFF;
}

.WishesOuter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  width: fit-content;
  background: #1652C9;
  border: 0.898605px solid #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
}

.WishesFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
}

.UpComBirthDate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
}

.BirthdayScrollVisible {
  padding: 25px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 82%;
}

.BirthdayScrollVisible::-webkit-scrollbar {
  display: none;
}

.OuterDiv10 {
  box-sizing: border-box;
  height: auto;
  background: #FFFFFF;
  border: 1.00616px solid #BABABA;
  border-radius: 25px;
  margin-top: 100px;
}

.OuterDiv10iao {
  box-sizing: border-box;
  height: auto;
  background: #FFFFFF;
  border: 1.00616px solid #BABABA;
  border-radius: 25px;
  margin-top: 0px;
}

/* .DateDesign {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background: #D8E2F6;
  border: 0.6px solid #3377FE;
  border-radius: 20px;
  width: fit-content;
  padding: 5px 15px;
} */

.DateDesignFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #000;
}

.Icon_color_TT {
  color: #1855CC;
}

.OuterSemiDiv1 {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}

.OuterSemiDiv2 {
  box-sizing: border-box;
  height: auto;
  /* background: #F4F4F4; */
  border: 0.6px solid #BABABA;
  border-radius: 25px;
}

.OuterSemiDiv3 {
  box-sizing: border-box;
  background: rgba(100, 29, 7, 0.1);
  border: 1px solid rgba(100, 29, 7, 0.5);
  border-radius: 20px;
  padding: 20px;
}

.AssignmentDiv {
  box-sizing: border-box;
  height: auto;
  border: 0.6px solid #BABABA;
  border-radius: 25px;
  padding: 20px;
}


.NumberCountDay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: max-content;
  white-space: nowrap;
  padding: 10px 15px;
  left: 0px;
  background: #606060;
  /* border-radius: 25px 0px; */
  border-radius: 0px 15px;
}

.back_color_subject {
  background-color: #F4F4F4;
  padding: 10px;
  border-radius: 15px;
}

.SubjectFontSub {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #252525;
}

.Time-Font-am {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #252525;
}

.UnitFontNumber {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #252525;
  cursor: pointer;
}

.UnitFontTopic {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #515151;
}

.ImgNameFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #4C4C4C;
}

.cursor-pointer {
  cursor: pointer;
}

.color_schedule {
  color: #2d8ec3 !important;
}

.color_complete {
  color: green !important;
}

.color_complete_background {
  background-color: green;
  color: #ffefef;
  border-radius: 50%;
  padding: 2px;
}

.color_In_complete {
  color: #ff6464;
}

.color_In_complete_background {
  background-color: #ff6464;
  color: #ffefef;
  border-radius: 50%;
  padding: 2px;
}

.borderCenert {
  border-bottom: 1px solid rgba(186, 186, 186, 0.7);
}

.Achieve_Padding {
  padding: 0px 25px 20px 25px;
}

.DiaryScroll {
  padding: 15px 20px 10px 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 80%;
}

.DiaryScroll::-webkit-scrollbar {
  display: none;
}

.DiaryScroll1 {
  padding: 5px;
}

.Img_Name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4C4C4C;
}

.Padding_25_15 {
  padding: 43px 15px 30px 15px;
}

.paddingTopBot {
  padding: 10px;
}

.Attend_Gap {
  display: flex;
  align-items: center;
  justify-content: space-between; 
}

.Padd2101 {
  padding: 20px 10px 0px 10px;
}

.pd25 {
  padding: 25px;
}

.PaddTBLR {
  padding: 25px 35px;
}

.ImgWidth {
  width: 60px;
  height: 60px;
}

.marginMinusTop {
  margin-top: -25px;
}

.VideoSize-Color {
  width: 20px;
  height: 20px;
  color: #252525;
}

.PaddingLR {
  padding: 10px 25px;
}

.padg1234 {
  padding: 25px 15px;
}

.VideoPointer {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.DdddFff {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.DisplayMobile {
  display: none;
}

.DisplayWeb {
  display: contents;
}

.MobileView {
  display: contents;
}

.PaddClassPoster {
  padding: 25px 35px;
}

.FlexMainDivAll {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
}

.FlexMainDivAll1 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.NewDown_Icon {
  display: none;
}

.P_15_20_10_20 {
  padding: 15px 20px 10px 20px;
}

.DisplayNoneWeb {
  display: none;
}

.ColorStyle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: white;
}

.A_Flex_Card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.A_Flex_Card1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.PaddingDiv9 {
  padding: 20px;
}

.BorderLineImg {
  padding-bottom: 15px;
  border-bottom: 0.770233px solid white;
}

.pad_20_20_0_20 {
  padding: 0px 20px;
}

.UpcomBirth {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 9.08px;
  width: fit-content;
  background: #1652C9;
  border: 0.898605px solid #FFFFFF;
  border-radius: 12px;
}

.Video_Gap_Mob {
  display: flex;
  align-items: center;
  gap: 10px;
}

.PD_20_Sub_U_S {
  padding: 5px 12px 10px 12px;
  width: 100%;
}

.left_arrow_TT {  
  background-color: #f36d6d;
  padding: 15px 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.left_arrow_font_TT {
  color: #fff;
}

.right_arrow_font_TT {
  color: #fff;
}

.right_position {
  position: absolute;
  background: rgb(96 158 96);
  border-radius: 50%;
  border: 0.6px solid #fff;
  padding: 1px 4px; 
  font-size: 10px;
  color: #fff;
  /* left: 4%; */
  right: 4%;
  top: 40%;
}

.left_position {
  position: absolute;
  background: #f36d6d;
  border-radius: 50%;
  border: 0.6px solid #fff;
  padding: 1px 4px; 
  font-size: 10px;
  color: #fff;
  left: 4%;
  top: 40%;
}

.right_arrow_TT {
  color: #fff;
  background-color: rgb(96 158 96);
  padding: 15px 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}



.BirthDiv1 {
  box-sizing: border-box;
  height: 676px;
  background: rgba(113, 79, 0, 0.1);
  border: 1px solid rgba(113, 79, 0, 0.5);
  border-radius: 25px;
}

.padfifteen {
  padding: 15px !important;
}

.BirthDiv2 {
  box-sizing: border-box;
  height: 676px;
  background: #F4F4F4;
  border: 1px solid #BABABA;
  border-radius: 25px;
  width: 100%;
}

.Today_Birth_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #252525;
  padding: 10px;
}

.Today_Birth_Fontcolor {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #3174FA;
  padding: 10px;
}

.Today_Birth_border {
  border-bottom: 3px solid #3174FA;
  width: 50%;
}


.Name_Birth_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #252525;
}

.Name_Class_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #252525;
}

.Button_Design {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  width: fit-content;
  background: #DFA212;
  border: 1px solid rgba(94, 65, 9, 0.6);
  border-radius: 25px;
  cursor: pointer;
}

.Button_Design_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
}

.Birth_Height {
  height: 225px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-bottom: 1px solid #B19F73;
}

.width_today_birth {
  width: 50% !important;
}

.Birth_Height::-webkit-scrollbar {
  display: none;
}

.Birth_Height_Upcoming {
  height: 215px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Birth_Height_Upcoming::-webkit-scrollbar {
  display: none;
}

.Search_Field {
  box-sizing: border-box;
  width: 251px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  border-radius: 32px;
  padding-left: 40px;
}

.Class_Button_Birth {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 18px;
  width: 180px;
  height: 37px;
  background: #D8E2F6;
  border: 0.6px solid #3377FE;
  border-radius: 22px;
}

.Class_Button_Birth_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #1855CC;
}

.StudentScroll_Birth {
  height: 525px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.StudentScroll_Birth::-webkit-scrollbar {
  display: none;
}

.Name_Birth_Name_A_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #252525;
}

.Name_Birth_Class_A_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #252525;
}

.Name_Birth_Date_A_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #252525;
}

.Ex_Schedule_View_more {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 15px;
  width: 379px;
  height: 37px;
  background: #D8E2F6;
  border: 0.6px solid #3377FE;
  border-radius: 22.5px;
}

.Ex_Schedule_View_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #1855CC;
}

.G_E_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #252525;
}

.G_E_Date_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #252525;
}

.G_E_Time_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #515151;
}

.U_Topic_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #252525;
}

.U_Topic_Les_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #515151;
}

.Img_Name_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #4C4C4C;
}


/* View More for Attendance Jai */

.viewAttend {
  height: 570px;
  background: #F4F4F4;
  border: 1px solid #BABABA;
  border-radius: 25.6628px;
  overflow: auto;
  padding: 20px;
}

.viewAttend1 {
  height: 570px;
  background: #F4F4F4;
  border: 1px solid #BABABA;
  border-radius: 25.6628px;
  padding: 20px;
}

.topicassignsubMenu {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #515151;
}

.viewAttend::-webkit-scrollbar {
  display: none;
}

.postViewmore {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #515151;
}

.postdateviewmore {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #515151;
}

.creamNumviewmore {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  background: rgba(55, 55, 55, 0.15);
  border-radius: 7px;
}

.creamNumviewmoreFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #474747;
}

.AttendViHead {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #252525;
  padding: 20px;
}

.viewAttends {
  padding-left: 5%;
  padding-right: 50px;
}

.nameAttenvi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.005em;
}

.numberAttenvi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 28.2291px;
}

.progressvi {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  margin-top: 80px;
}

.barboriee1vi {
  width: 20px;
  border-radius: 30px 30px 0px 0px;
  background: #BBDEFB;
}

.barboriee1vi:hover {
  width: 20px;
  border-radius: 30px 30px 0px 0px;
  background: #3073F9;
}

.barboriee1vi:hover .tooldiv {
  visibility: visible !important;
}

.react-calendar {
  width: auto !important;
  max-width: 100%;
  background: #F4F4F4 !important;
  border: 1px solid #F4F4F4 !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-top: 60px;
}

.ClassendarVi {
  padding: 60px 20px;
}


.tooldiv {
  width: auto;
  height: auto;
  border: 1px solid #606060;
  border-radius: 15px;
}


.toolfirst {
  background: #E6E6E6;
  border-radius: 11px 11px 0px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.005em;
  color: #252525;
  height: 20px;
  padding-top: 5px;
}

.toolrsec1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 1px;
  color: #1C56CA;
  padding-bottom: 5px;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
}

.toolrsec2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.005em;
  color: #05A26F;
  padding-bottom: 5px;
}

.toolrsec3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.005em;
  color: #FB4C4C;
  padding-bottom: 5px;
}


.nameAchivevi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: #252525;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.nameAchivevi_colorbluesih {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #3377FE;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  border-bottom: 3px solid #3377FE;
  padding-bottom: 3px;
}

.eioMT {
  margin-top: 0px !important;
}

.nameDecachivVi {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #252525;
}


.DateDesign {
  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; */
  /* box-sizing: border-box; */
  /* width: 147px; */
  /* height: 38px; */
  /* background: #D8E2F6; */
  /* border-radius: 10px; */
  /* width: fit-content; */
  /* padding: 5px 15px; */
}

.border_time {
  border: 1px solid #bababa;
  padding: 3px;
  border-radius: 10px;
}

.DateDesignPlus {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  border: 0.6px solid #3377FE;
  border-radius: 20px;
  width: fit-content;
  padding: 2px 20px 0px 35px;
  margin-top: 2px;
  margin-left: -41px;
}

.AddTitleFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #252525;
}

.modal-header {
  padding: 10px 0px !important;
}

.DescFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #5B5B5B;
}

.M_Close {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background: rgba(64, 64, 64, 0.1);
  border: 0.6px solid rgba(55, 55, 55, 0.15);
  border-radius: 9px;
  padding: 2px 10px 2px 2px !important;
}

.Cal_Date {
  box-sizing: border-box;
  width: 169px;
  height: 49px;
  background: #F4F4F4;
  border: 1px solid #D0D0D0;
  border-radius: 10px;
}

.TimeDropdown {
  border: none;
  background-color: #F4F4F4;
  cursor: pointer;
}

.WeeklyOuterDiv {
  box-sizing: border-box;
  height: auto;
  background: #F4F4F4;
  border: 1px solid #D0D0D0;
  border-radius: 10px;
}

.Diary_Modal_Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0067D2;
  width: 31px;
  height: 32px;
  border-radius: 5px;
}

.P_Week_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #252525;
}

.Week_Days_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #5B5B5B;
}

.Diary_Modal_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #484848;
}

.Notify_Modal {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #252525;
}

.Modal_Diary_Time_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #5B5B5B;
}

.Diary_Prio_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #252525;
}

.Modal_Button_Add {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 30px;
  gap: 10px;
  width: 93px;
  height: 41px;
  background: #0166CF;
  border: 1px solid #024285;
  border-radius: 20px;
  cursor: pointer;
}

.Button_Add_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}

.Modal_LHM_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #5B5B5B;
}

.datediarysubmenu {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #252525;
}

.contentSubmenu {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #1855CC;
  padding: 10px 0px;
}

.paintContentSubmenu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  overflow-y: scroll;
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  border-radius: 10px;
  margin-top: 15px;
}

.paintContentSubmenu::-webkit-scrollbar {
  display: none;
}

.diarySubmenuDate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 13px;
  color: #1D1D1D;
}

.ellipsePlus {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.05);
  border: 0.5px solid rgba(133, 133, 133, 0.2);
  border-radius: 29px;
}

.seconContentsubmenu {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #494949;
}

.thirdContentsubmenu {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #505050;
}

.sevengap {
  gap: 780px;
}

.DateDesignFont {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #000;
}


.gapnine {
  gap: 250px;
}

/* View More for Attendance Jai */

.Scroll_Table {
  overflow-x: auto;
  height: 490px;
}

.Scroll_Table::-webkit-scrollbar {
  display: none;
}



/* Style  for    K_a_m_p_u_s    N_e_w_D_a_s_h_b_o_a_r_d      */

.MTop {
  margin-top: 60px !important;
  margin-bottom: 40px;
}

.AttendanceFlex {
  display: flex;
  flex-direction: row;
  width:100%;
  align-items: center;
  padding: 10px 0px;
}

.AttendTotalDays {
  display:flex;
  flex-direction:row;
  gap: 30px;
  padding-left: 25px;
}

.Chart_Month {
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
  align-items:flex-end;
}

.Attend_Month {
  display:flex;
  flex-direction:row;
  gap: 10px;
  position: absolute;
  margin-top: 0px;
}

.MTop_Assignment {
  margin-top: 55px;
  margin-bottom: 40px;
}

.MTop_Diary {
  margin-top: 95px;
  margin-bottom: 35px;
}

.MTop_Examchedule {
  margin-top: 55px;
  margin-bottom: 30px;
}

.PCP_Assignment {
  display:flex;
  align-items: center;
  padding: 20px 20px 0px 60px;
  gap:40px;
}

.MTop_Exam_Results {
  margin-top: 55px;
  margin-bottom: 30px;
}

.Assignment_None {
  display: none;
}

.MTop_Achievements {
  margin-bottom: 30px;
}

.MTop_Birthday {
  margin-top: 60px;
  margin-bottom: 35px;
}

.Achieve_Scroll {
  overflow: auto;
  height: 500px;
}

.TextArea_Diary {
  width: 100% !important;
}

.Assignment_Height_PCP {
  height: 640px;
  overflow: hidden;
}



/* Responsive design for Mobile  */

@media (min-width:279px) and (max-width:480px) and (orientation: portrait) {

  /* Css for Kampus N_E_W Dashboard for KAMPUS for Mobile Portrait */ 

  .Diary_Title_Mobile {
    font-size: 17px;
    font-weight: 500;
  }

  .Class_Poster_Css {
    width: 100%;
    height: 165px;
  }

  .Diary_Height {
    height: 56px;
    border: 1px solid #BABABA;
    padding: 15px;
    border-radius: 10px;
  }
  
  .Diary_Button_Css1 {
    padding: 5px 15px;
    height: 40px;
    border: 1px solid #BABABA;
    color: #232323;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
  }

  .Diary_Button_Css2 {
    padding: 5px 15px;
    height: 40px;
    border: 1px solid #1956CE;
    color: #FFFFFF;
    background-color:#1956CE;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
  }

  .Diary_Add_Css {
    position: fixed;
    bottom: 90px;
    right: 30px;
    z-index: 99999999;
  }

  .Assignment_Height_PCP {
    height: 640px;
    overflow: hidden;
  }

  .paintContentSubmenu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    margin-top: 15px;
  }

  .seconContentsubmenu {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #252525;
  }

  .diary_Padding {
    padding: 0px !important;
  }

  .Diary_Back {
    background-color: #f4f4f4 !important;
  }

  .Diary_Display_None {
    display:none!important ;
  }

  .Weekly_Display_None {
    display: none !important;
  }

  .btn-group {
    width: 100% !important;
  }

  .dropdown-menu.show {
    width: 100% !important;
  }

  .DropDown_Css {
    width: 100% !important;
    border: 1px solid rgb(202, 202, 202);
    padding: 10px 15px !important;
    background-color: rgb(230, 230, 230) !important;
    color: black !important;
  }

  .Birth_Height_Upcoming {
    height: 305px;
}

  .Pad_10_15 {
    padding: 10px 15px;
  }
  
  .Today_Birth_Font {
    color: #6D6D6D;
  }

  .Achieve_Scroll {
    overflow: hidden;
    height: auto;
  }

  .nameAchivevi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #6D6D6D;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
  }

  .Birthday_Mobile_Color {
    background-color: #E6E6E6;
    border:1px solid #CACACA;
  }

  .Assign_Mobile_Color {
    background-color: #E6E6E6;
    padding:15px 0px 0px 0px;
    border:1px solid #CACACA;
  }

  .BirthDiv1 {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
  }

  .Birth_Input {
    position: relative;
    display: flex;
    align-items: center;    
  }

  .Birth_Search {
    position: absolute;
    top: 10px;
    left: 8px;
    color: #585858;
  }

  .Birth_Input_Css {
    width: 200px;
    height: 37px;
    padding-left: 30px;
    padding-right: 10px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 32px;
  }

  .Select_Birth_Css {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px;
    height: 32px;
    background: #D8E2F6;
    border: 0.6px solid #3377FE;
    border-radius: 22px;
    color: #3377FE;
  }

  .Assignment_None {
    display: contents;
  }

  .P_0 {
    padding-top: 0px !important;
  }


  .PCP_Assignment {
    display: flex;
    align-items: center;
    justify-content: space-evenly;    
    padding: 0px 0px 0px 0px;
    gap:0px;
  }

  .Acheive_None {
    display: none !important;
  }

  .Assignment_PCP {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #6D6D6D;
  }

  .Attend_Month {
    display:flex;
    flex-direction:row;
    gap: 10px;
    position: absolute;
    margin-top: 30px;
  }


  .ClassendarVi {
    padding: 60px 15px 5px 15px;
  }
  .Scroll_Attendance_Month {
    height: 515px;
    overflow-y: scroll;
  }

  .Scroll_Attendance_Month::-webkit-scrollbar {
    display: none;
  }  


  .Back_None {
    background: none !important;
  }

  .react-calendar {
    width: auto !important;
    max-width: 100%;
    background: #F4F4F4 !important;
    border: 1px solid #F4F4F4 !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    margin-top: 30px;
  }

  .Padding_0px {
    padding: 0px !important;
  }

  .barboriee1vi {
    width: 20px;
    border-radius: 30px 30px 0px 0px;
    background: #BBDEFB;
  }

  .viewAttend {
    height: 430px;
    background: none;
    border: 0px solid #E3E3E3;
    border-radius:0px;
    overflow: auto;
    padding: 5px;
  }

  .Attendance_Card {
    height: 350px !important;
    background-color: #ffffff !important;
    border: 1px solid #E3E3E3;
    border-radius: 25.6628px;
    padding: 20px;
  }

  .viewAttend1 {
    height: 440px;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 25.6628px;
    padding: 20px;
  }

  .Chart_Month {
    display:flex;
    flex-direction:row;
    align-items:flex-end;
    justify-content: space-evenly;
  }

  .Width_Scroll {
    width: 600px;
    margin-top: 65px;
  }

  .Width_Scroll::-webkit-scrollbar {
    display: none;
  }
  
  .barboriee1vi:hover {
    width: 20px;
    border-radius: 30px 30px 0px 0px;
    background: #3073F9;
  }
  
  .barboriee1vi:hover .tooldiv {
    visibility: visible !important;
  }

  .progressvi {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    margin-top: 30px;
  }

  .numberAttenvi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }

  .AttendTotalDays {
    display:flex;
    flex-direction:row;
    gap: 10px;
    padding-left: 0px;
    position: absolute;
    margin-top: 30px;
  }

  .nameAttenvi {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .AttendanceFlex {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width:100%;
    padding: 0px 0px;
  }

  .AttendViHead {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
    padding: 0px;
    position: absolute;
  }

  .MTop {
    margin-top: 0px !important;
    margin-bottom: 0px;
  }

  .MTop_Diary {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .MTop_Examchedule {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .MTop_Assignment {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .MTop_Exam_Results {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .MTop_Birthday {
    margin-bottom: 0px;
  }


  .Scroll_Table {
    overflow-x: auto;
    height: 450px;
  }
  
  .Scroll_Table::-webkit-scrollbar {
    display: none;
  }

  .title-txt-label {
    padding: 15px 10px;
  }

  .DisplayNoneWeb {
    display: contents;
  }

  .FlexMainDivAll {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .FlexMainDivAll1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .DTC-Card {
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 28px;
    margin-top: 25px;
  }

  .SclPosterDiv {
    height: auto;
    border: 1.00616px solid #CBCBCB;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 28px;
  }

  .padg_Scl_1234 {
    padding: 10px 15px;
    display: flex;
    gap: 15px;
    overflow-x: scroll;
  }

  .pd25 {
    padding: 15px;
  }

  .pd_New_25 {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .MainDiv1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
  }

  .padg_Scl_1234::-webkit-scrollbar {
    display: none;
  }

  .D-ES-Card {
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    border: 1.00616px solid #CBCBCB;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }

  .D-ES-Card-Height {
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    border: 1.00616px solid #CBCBCB;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }

  .NewDown_Icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 10px;
    width: 24px;
    height: 21.95px;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 12px;
  }

  .Exam-Schedule {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    padding: 15px 15px;
    left: 0px;
    background: white !important;
    border-radius: 15px 15px;
  }

  .Exam-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #252525;
  }

  .Padding_25_15 {
    padding: 10px 15px;
    height: 215px;
    overflow-x: scroll;
    margin-bottom: 15px;
  }

  .Padding_25_15::-webkit-scrollbar {
    display: none;
  }

  .Subject-Unit {
    box-sizing: border-box;
    background: #EDFFF9;
    border: 0.5px solid #85C2AE;
    border-radius: 12px;
  }

  .D_A_M-Css {
    display: flex;
    flex-direction: column;
    padding: 5px 18px;
    border-radius: 15px;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    border: 0.919367px solid #252525;
  }

  .NT-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #252525;
  }

  .Month-font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: #252525;
  }

  .E-Font1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #252525;
  }

  .Time-font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #252525;
  }

  .Unit-Name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #494949;
  }

  .Today-Class {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    padding: 15px 15px !important;
    left: 0px !important;
    background: white !important;
    border-radius: 15px 15px !important;
  }

  .Today-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #252525;
  }

  .ColorFont {
    color: white;
  }

  .Date-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
  }

  .D-FlexDV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px !important;
    padding-left: 0px !important;
  }

  .DisplayNone {
    display: none;
  }

  .View-More-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #2F71F5;
    cursor: pointer;
  }

  .Subject-Timing {
    background-color: #F4F4F4;
    height: 215px !important;
    padding: 10px 0px;
    width: 290px;
    height: auto;
    border: 1px solid #DFDFDF;
    border-radius: 25px;
  }

  .navbar-title {
    width: 30%!important;
  }

  .PaddingLR {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .padg1234 {
    padding: 6px 10px 10px 10px!important;
    display: flex;
    gap: 15px;
    overflow-x: scroll;
  }

  .padg1234::-webkit-scrollbar {
    display: none;
  }

  .E-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #252525;
  }

  .VideoPointer {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .T-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #252525;
  }

  .Unit-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #585858;
  }

  .InnerDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
  }

  .Img_Name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4C4C4C;
  }

  .DdddFff {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .DisplayMobile {
    display: contents;
  }

  .DisplayWeb {
    display: none;
  }

  .OuterDiv1 {
    background: #FFFFFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    height: auto;
    border: 1.00616px solid #CBCBCB;
  }

  .PAT_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #3EAD89;
  }

  .P_15_20_10_20 {
    padding: 0px 5px;
  }

  .WorkingDiv {
    box-sizing: border-box;
    background-color: #F8F8F8;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .OuterDiv2 {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1.00616px solid #CBCBCB;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    height: 310px;
  }

  .SclComp {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #252525;
  }

  .View-More-FontColor {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #2F71F5;
  }

  .ColorStyle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #4F4F4F;
  }

  .WorkingDiv1 {
    box-sizing: border-box;
    padding: 15px 15px;
    background: #FFF9EA;
    border: 1px solid #FFE6A4;
    border-radius: 12px;
  }

  .TimeFontCol {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #585858;
  }

  .OuterDiv3 {
    box-sizing: border-box;
    height: 300px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border: 1.00616px solid #CBCBCB;
    border-radius: 15px;
  }

  .OuterDiv3_Height {
    box-sizing: border-box;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border: 1.00616px solid #CBCBCB;
    border-radius: 15px;
  }

  .Exam-Schedule1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    padding: 15px 20px;
    left: 0px;
    background-color: #fff;
    border-radius: 25px 15px;
  }

  .WorkingDiv2 {
    box-sizing: border-box;
    background-color: #FCFCFC;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    padding: 15px 15px;
  }

  .Date-Font-Color {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #252525;
  }

  .Subject-Border {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    background: #DDDDDD;
    border-radius: 15px;
  }

  .Subject-Border-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #252525;
  }

  .Subject-Topic {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #6C6C6C;
  }


  .DiaryScroll {
    padding: 10px;
    height: 235px;
  }

  .Ex-Schedule-Height {
    padding: 10px 15px;
  }

  .OuterDiv4 {
    box-sizing: border-box;
    height: 480px;
    background: linear-gradient(142.28deg, #0E7933 0.72%, #1CB050 100%);
    border-radius: 15px;
  }

  .OuterDiv6 {
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 28px;
  }

  .TotalCount-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #252525;
  }

  .PerCountExRs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 6px;
    gap: 10px;
    width: 44px;
    height: 21px;
    background: rgba(5, 162, 111, 0.2);
    border-radius: 5px;
  }

  .PerCountExRsFont {
    width: 32px;
    height: 11px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 11px;
    letter-spacing: 0.005em;
    color: #05A26F;
  }

  .WorkingMobileDiv {
    background: #FCFCFC;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    padding: 15px;
  }

  .OuterDiv7 {
    box-sizing: border-box;
    height: auto;
    background: #FFFFFF;
    border: 1.00616px solid #BABABA;
    border-radius: 28px;
  }

  .BirthdayColor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    padding: 15px 20px;
    left: 0px;
    background-color: #fff;
    border-radius: 15px 0px;
  }

  .OuterDiv8 {
    box-sizing: border-box;
    height: auto;
    background: #FFFFFF;
    border: 1.00616px solid #BABABA;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 28px;
  }

  .OuterDiv9 {
    box-sizing: border-box;
    height: auto;
    left: 0px;
    top: 0px;
    background: #F4F4F4;
    border-radius: 12px;
  }

  .Today-Font-White {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #252525;
  }

  .PaddingDiv9 {
    padding: 15px;
  }

  .LilFont {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #252525;
  }

  .LilClassFont {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #5B5B5B;
  }

  .BirthdayScrollVisible {
    padding: 10px 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 250px;
    margin-bottom: 20px;
  }

  .WishesOuter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    width: -moz-fit-content;
    width: fit-content;
    background: rgba(51, 119, 254, 0.15);
    border: 0.635328px solid #3377FE;
    border-radius: 12px;
    cursor: pointer;
  }

  .WishesFont {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #3377FE;
  }

  .BorderLineImg {
    padding-bottom: 15px;
    border-bottom: 0.544567px solid #BABABA;
  }

  .PaddTBLR {
    padding: 20px 5px;
    overflow-x: scroll;
    display: flex;
    overflow-y: hidden;
  }

  .PaddTBLR::-webkit-scrollbar {
    display: none;
  }

  .pad_20_20_0_20 {
    padding: 0px 20px;
  }

  .UpcomBirth {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3.63044px 8.1685px;
    gap: 9.08px;
    width: fit-content;
    background: #F4F4F4;
    border-radius: 12px;
  }

  .UpComBirthDate {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: #252525;
  }

  .GradeCard {
    width: 220px;
    height: 240px;
    padding: 20px;
    box-sizing: border-box;
    background: rgba(236, 197, 4, 0.1);
    border: 0.5px solid #FFD502;
    border-radius: 10px;
  }

  .GradeCard1 {
    box-sizing: border-box;
    background: rgba(236, 197, 4, 0.1);
    border: 0.5px solid #FFD502;
    border-radius: 10px;
  }

  .GradeCard2 {
    box-sizing: border-box;
    background: rgba(171, 92, 83, 0.11);
    border: 0.5px solid #D58C84;
    border-radius: 10px;
  }

  .GradeCard3 {
    box-sizing: border-box;
    background: #F8F8F8;
    border: 0.5px solid #B5B5B5;
    border-radius: 10px;
  }

  .A_Flex_Card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .Birth_Card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: fit-content;
  }

  .ScrollNew {
    overflow-x: scroll;
  }

  .ScrollNew::-webkit-scrollbar {
    display: none;
  }

  .A_Flex_Card1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }


  .OuterCard-Info {
    height: 380px;
    background: #FFFFFF;
    box-shadow: 5px 10px 10px 7px rgb(0 0 0 / 20%);
    border-radius: 13px;
  }


  .PaddClassPoster {
    padding: 25px 20px;
  }

  .shollpostreMobile {
    width: 100%;
    height: 100%;
    background: #FCFCFC;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E0E0E0;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 10%;
    padding-left: 3%;
    padding-top: 2%;
  }

  .Schoolmoile_date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #4B4B4B;
  }

  .Schoolmobile_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #252525;
  }

  .Schoolmobile_content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #585858;
  }

  .NumberCountDay {
    display: none;
  }

  .pad_TC_Sub {
    padding: 5px 20px;
  }

  .SubjectFontSub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #252525;
  }


  .Video_Gap_Mob {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .OuterSemiDiv1 {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    height: 515px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .OuterSemiDiv1::-webkit-scrollbar {
    display: none;
  }

  .Time-Font-am {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #252525;
    background-color: #ededed;
    border-radius: 10px;
    padding: 3px 5px;
  }

  .OuterSemiDiv2 {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 0.6px solid #BABABA;
    border-radius: 15px;
  }

  .PD_20_Sub_U_S {
    padding: 5px 7px;
  }

  .UnitFontNumber {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #252525;
  }

  .UnitFontTopic {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #515151;
  }

  .ImgNameFont {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #4C4C4C;
  }

  .OuterDiv10 {
    box-sizing: border-box;
    height: auto;
    padding: 15px 0px;
    background: #FFFFFF;
    border: 0px solid #BABABA;
    border-radius: 15px;
    margin-top: 0px;
  }

  .OuterDiv10::-webkit-scrollbar {
    display: none;
  }

  .Header-Style {
    padding: 15px 10px;
    background: #FFFFFF;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }


  /* Css for Kampus N_E_W Dashboard for KAMPUS for Mobile Portrait */


  .Margin_Left_Card {
    width: 47%;
  }

  .Booklist_Subject {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #1B252F;
  }

  .Subject_container {
    padding: 10px 3px;
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }

  .Units_Count_Text {
    margin-left: 25%;
  }

  .Manoj_span {
    font-size: 10px;
  }

  .myProgress {
    width: 70% !important;
  }

  .Card_Book_List {
    margin-top: 50px;
    border-radius: 8px !important;
  }

  .myBar {
    height: 7px !important;
  }

  .grennmyBar {
    height: 7px !important;
  }

  .orangemyBar {
    height: 7px !important;
  }

  .purplemyBar {
    height: 7px !important;
  }

  .pinkmyBar {
    height: 7px !important;
  }

  .Image_Size {
    width: 72px;
    height: 116px;
    margin-top: -25px;
  }

  .OuterDiv5 {
    box-sizing: border-box;
    height: 520px;
    background: linear-gradient(135deg, #0E7933 0%, #1CB050 100%);
    border-radius: 15px;
  }

  .Book_U_PC {
    display: flex;
    flex-direction: row;
    font-size: 9px;
    font-family: 'Inter';
  }

  .BookOpen_Icon {
    width: 14px;
    height: 14px;
  }

  .Pad_Mar {
    padding: 0px 5px;
    margin-top: 5px;
  }

  .web-display-none {
    display: contents;
  }

  .mob-display-none {
    display: none !important;
  }

  .Display_Center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .School_Tooltip {
    left: 1%;
    max-width: 300px;
  }

  .School_Tooltip_Cube {
    display: flex;
    justify-content: flex-end;
    margin-right: 85%;
  }

  .ContentTooltip_Icon {
    left: 15% !important;
    width: 250px !important;
  }

  .font-12-px {
    font-size: 14px !important;
  }

  .Percent_Font {
    font-size: 10px;
    font-weight: 500;
  }

  .myprogress_pertg {
    font-size: 11px;
    font-family: 'Inter';
    padding-top: 5px;
  }

  .TextArea_Diary {
    width: 100% !important;
  }




  /* Mobile design for Teacher dashboard Css */

  .Subject-Timing_TD {
    padding: 10px 0px;
    border-radius: 10px;
    border: 1px solid #D5CCFF;
    background-color: #F0EDFF;
    height: auto;
    width: 290px;
  }

  .DTC-Card_TD {    
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.1);
    border-radius: 28px;
    margin-top: 25px;
  }

  .padg1234_TD {
    padding: 15px 10px 20px 10px!important;
    display: flex;
    gap: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .padg1234_TD::-webkit-scrollbar {
    display: none;
  }

  .Attend_None_TD {
    display: none;
  }

  .Birthday_Mobile_Color_TD {
    background-color: #E6E6E6;
    border: 1px solid #CACACA;
    padding: 10px 0px 0px 0px;
  }

  .Today_Birth_Fontcolor_TD {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #3174FA;
    padding: 10px;
  }

  .Today_Birth_border_TD {
    border-bottom: 3px solid #3174FA;
    width: 50%;
  }

  .Today_Birth_Font_TD {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #6D6D6D;
    padding: 10px;
  }

  .MTop_TD {
    margin-top: 0px !important;
    margin-bottom: 40px;
  }

  .AttendanceFlex_TD {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .Pad_0px_TD {
    padding: 0px !important;
  }

  .ProgressBarColor_CA_TD {
    width: 50%; 
  }

  .viewAttend_TD {
    height: 400px;
    background-color: white;
    border: 1px solid #BABABA;
    border-radius: 25.6628px;
    overflow: auto;
    padding: 25px;
  }

  .viewAttend1_TD {
    height: 570px;
    background: white;
    border: 1px solid #BABABA;
    border-radius: 25.6628px;
    padding: 0px 0px 20px 0px;
  }

  .Diary_TD {
    background: white;    
  }

  .OuterDiv2_TD {
    box-sizing: border-box;
    height: 300px;
    left: 0px;
    top: 0px;
    border-radius: 25.663px;
    background: white;
  }

  .WorkingDiv1_TD {
    box-sizing: border-box;
    padding: 15px 15px;
    border-radius: 12px;
    border: 1px solid #005FC2;
    background: #EFF7FF;
  }

  .View-More-Font_TD {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #252525;
    cursor: pointer;
  }

  .View-More-FontColor_TD {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #252525;
  }

  .WorkingDiv2_TD {
    box-sizing: border-box;
    border-radius: 15px;
    border: 1px solid #FFE6A4;
    background: #FFF9EA;
    padding: 15px 15px;
  }

  .Subject-Border_TD {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    gap: 12.75px;
    border: none;
    border-radius: 11px;
    background: #FFEEC2;
  }

  .MTop_Assignment_TD {
    margin-top: 0px !important;
    margin-bottom: 10px;
  }

  .PaddClassPoster_TD {
    padding: 20px 20px 10px 20px;
  }

  .Padd_School_Poster_TD {
    padding: 25px 20px;
  }  

  .OuterDiv8_TD {
    box-sizing: border-box;
    height: 340px;
    border-radius: 25.674px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    background: #FFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.10);
  }

  .WishesOuter_TD {
    border-radius: 12px;
    border: 0.635px solid #3377FE;
    background: rgba(51, 119, 254, 0.15);
    cursor: pointer;
  }

  .UpcomBirth_TD {
    background: #F4F4F4;
    cursor: pointer;
  }

  .Birthday_TD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    padding: 15px 20px;
    left: 0px;
    border-radius: 25.674px 0px;
    background: none;
  }

  .ColorFont_TD {
    color: black;
  }

  .title-txt-label_TD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    white-space: nowrap;
    padding: 15px 0px 15px 20px;
    left: 0px;
    background-color: white;
    border-radius: 25px 0px;
  }

  .OuterDiv10iao_TD {
    box-sizing: border-box;
    height: auto;
    margin-top: 0px;

    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    background: #FFF;
    box-shadow: 0px 10px 32px -4px rgba(0, 0, 0, 0.10);
  }

  .Dropdown_ER_TD {
    display: inline-flex;
    height: 28px;
    padding: 2px 10px; 
    align-items: center;
    border-radius: 22.5px;
    border: 0.6px solid #3377FE;
    background: rgba(43, 108, 239, 0.10);
    color: #1855CC;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  .Drop_Mob_Font {
    font-size: 13px !important;
  }

  .Font_70_TD {
    color: #07A571;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .ER_Pass_TD {
    color: #07A571;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  .Font_30_TD {
    color: #FD3A20;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .ER_Fail_TD {
    color: #FD3A20;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  .ER_Total_TD {
    color: #3073F9;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

  .Font_100_TD {
    color: #3073F9;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  .Exam_Result_VM_TD {
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .Piechart_TD {
    padding: 20px 10px;
    height: 400px;
  }

  .Grade_Height_TD {
    height: 300px;
    overflow-y: scroll;
  }

  .OuterDiv10_TD {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px 0px;
  }

  .OuterDiv10_TD::-webkit-scrollbar{
    display: none;
  }  



}

/* Responsive design for Mobile  */





/* Responsive design for Tablet  */
@media (min-width: 481px) and (max-width: 1200px) and (orientation: landscape) {

  /* Css for Kampus N_E_W Dashboard for KAMPUS for Tablet Landscape */

  .Birth_Height_Upcoming {
    height: 215px;
  }

  .Class_Poster_Css {
    width: 100%;
    height: 180px;
  }

  .TextArea_Diary {
    width: 100% !important;
  }

  .Unit-Font {
    font-size: 13px;
  }

  .Img_Name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #4C4C4C;
  }

  .Padding_25_15 {
    padding: 42px 15px 15px 15px;
  }

  .Attend_Gap {
    gap: 15px;
  }

  .WorkingDiv {
    box-sizing: border-box;
    background-color: #F8F8F8;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .PAT_Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #494949;
  }

  .Padd2101 {
    padding: 5px 10px 0px 10px;
  }

  .OuterCard-Info {
    height: 390px;
    background: #FFFFFF;
    box-shadow: 5px 10px 10px 7px rgba(0, 0, 0, 0.2);
    border-radius: 13px;
  }

  .OuterDiv4 {
    box-sizing: border-box;
    height: 500px;
    background: linear-gradient(142.28deg, #0E7933 0.72%, #1CB050 100%);
    border-radius: 15px;
  }

  .InnerDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
  }

  .pd25 {
    padding: 15px;
  }

  .Off-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #252525;
  }

  .Mail-Font {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #434343;
  }

  .HeightCScroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 415px;
  }

  .OuterDiv6 {
    box-sizing: border-box;
    height: 500px;
    background: #FFFFFF;
    border: 1.00662px solid #BABABA;
    border-radius: 25.6744px;
  }

  .PaddTBLR {
    padding: 25px 15px;
  }

  .GradeCard1 {
    height: 240px;
    background: #FFFFFF;
    box-shadow: 5.82314px 11.6463px 17.4694px 3.49389px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    padding: 15px;
  }

  .GradeCard2 {
    height: 240px;
    background: #FFFFFF;
    box-shadow: 5.82314px 11.6463px 17.4694px 3.49389px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    padding: 15px;
  }

  .GradeCard3 {
    height: 240px;
    background: #FFFFFF;
    box-shadow: 5.82314px 11.6463px 17.4694px 3.49389px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    padding: 15px;
  }

  .ImgWidth {
    width: 50px;
    height: 50px;
  }

  .ImgMedalWidth {
    width: 40px;
    height: 50px;
  }

  .marginMinusTop {
    margin-top: -10px;
  }

  .OuterSemiDiv2 {
    box-sizing: border-box;
    height: auto;
    background: #F4F4F4;
    border: 0.6px solid #BABABA;
    border-radius: 25px;
  }

  .OuterSemiDiv1 {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .SubjectFontSub {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #252525;
  }

  .Time-Font-am {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    color: #252525;
  }

  .VideoSize-Color {
    width: 20px;
    height: 20px;
    color: #252525;
  }

  .paddingTopBot {
    padding: 10px;
  }

  .DdddFff {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  /* Css for Kampus N_E_W Dashboard for KAMPUS for Tablet Landscape */




  .Margin_Left_Card {
    width: 23%;
  }


  .Units_Count_Container {
    width: 45%;
  }

  .Subject_container {
    padding: 10px 20px 0px 20px;
    width: 100vw;
    height: 100vh;
  }

  .Portions_covered_Text_Container {
    width: 50%;
  }

  .Image_Size {
    height: 140px;
  }

  .Container {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 60px 30px 60px;
  }

  .BookOpen_Icon {
    width: 14px;
    height: 14px;
  }

  .Manoj_span {
    font-size: 12px;
  }

  .myProgress {
    width: 70% !important;
  }

  .myBar {
    height: 7px !important;
  }

  .grennmyBar {
    height: 7px !important;
  }

  .orangemyBar {
    height: 7px !important;
  }

  .purplemyBar {
    height: 7px !important;
  }

  .pinkmyBar {
    height: 7px !important;
  }

  .Card_Book_List {
    height: 75%;
  }

  .Pad_Mar {
    padding: 5px 10px 5px 10px;
  }

  .myprogress_pertg {
    font-size: 10px;
    font-family: 'Inter';
    padding-top: 5px;
  }

  .ProNumber_TD {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #252525;
  }

  .ProgressBarColor_TD {
    width: 62%;
    background-color: #E5E5E5;
    border-radius: 50px;
    height: 8px;
    margin-top: 2px;
  }

  .ProgressBarColorIn_TD {
    height: 8px;
    border-radius: 50px;
  }

  .PercentNumber_TD {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #BABABA;
  }

  .PaddClassPoster_TD {
    padding: 20px 15px 10px 15px;
  }

  .Piechart_Width {
    width:170px;
  }

  .Sub_Phy_TD {
    color: #252525;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  .ER_Pass_TD {
    color: #07A571;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  .Font_70_TD {
    color: #07A571;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  
  .Span_Pass_TD {
    color: #07A571;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  
  .ER_Fail_TD {
    color: #FD3A20;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }
  
  .Font_30_TD {
    color: #FD3A20;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  
  .Span_Fail_TD {
    color: #FD3A20;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  
  .ER_Total_TD {
    color: #3073F9;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }
  
  .Font_100_TD {
    color: #3073F9;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }
  
  .Span_Total_TD {
    color: #3073F9;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }

  .Padd_School_Poster_TD {
    padding: 20px 15px;
  }

  .Mon_Ex_Font_TD {
    color: #252525;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  /* Teacher Dashboard Css */

  .ExamResult_Height_TD {
    height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .HeightCScroll_TD {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 475px;
  }



  /* Teacher Dashboard Css */


}

/* Responsive design for Tablet  */


/* Style for Notification  */

.Notify_Design {
  position: absolute;
  right: 12%;
  width: 35%;
  height: 300px;
  z-Index: 1;
  padding: 15px;
  border-radius: 5px;
  background-Color: #ffffff;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  overflow-x: auto;
  top: 54px;
}

.Notify_Design::-webkit-scrollbar {
  display: none;
}

.Notify_Design {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.Notify_Font {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #35414D;
}

.display_None_Web_A {
  display: none;
}

.LeftArrow_Notify {
  display: flex;
  gap: 10px;
  background-color: #ffffff;
  padding: 10px;
  position: fixed;
  width: 100%;
}

.Display_Notify_None {
  display: none;
}

.Res_Pub {
  display: none;
}

.Noti_Karthick {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #35414D;
}

.R_F_C {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8F9CAA;
}

.Mins {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8F9CAA;
}

.D_Flex_1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0px;
}

.Class_XI {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ADB9C7;
}

.Content_H6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #35414D;
  margin-top: 10px;
  padding: 0px 60px 0px 71px;
  line-height: 1.3;
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Content_H6:hover {
  overflow: visible;
  white-space: normal !important;
}

.Announcement_Noti {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #1B252F;
}

.Class_Holiday {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #35414D;
  padding: 10px;
}


.V_Clarify {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #3377FF;
  cursor: pointer;
}

.Noti_Bell {
  background-color: #E6ECF1;
  border-radius: 50px;
  padding: 12px;
  color: #8F9CAA;
  width: 50px;
  height: 50px;
}

.Noti_U_T_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #1B252F;
}

.View_R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3377FF;
  margin-top: 8px;
  cursor: pointer
}

.Notify_Flex_Row_Col {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.Back_Padd_Mar {
  border-bottom: 1px solid #E6ECF1;
  padding: "15px 0px 0px 0px !important"
}

/* Style for Notification  */

/* start footer KPOST chat css */

.KpostIf_IyQ {
  position: fixed;
  right: 5px;
  bottom: 0px;
  z-index: 300;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  background-color: transparent;
}

.Iframe_PItQ_X {
  height: 100vh;
  width: 500px;
  position: absolute;
  right: 10px;
  bottom: 0px;
  background-color: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  overflow: auto;
  padding: 0px 0px 0px 0px;
  z-index: 999999;
}

.Iframe_PItQ_X::-webkit-scrollbar {
  display: none;
}

.Iframe_PItQ_X {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* end footer KPOST chat css */

.Cla_Status {
  display: none;
}

/* teeacher Dashboard */

.Card_Teacher_classNotice {
  width: auto;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 20px;
}

.clascountteach {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.teach12 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}

.colortchclas {
  color: #3377FF;
}

.overflowteach {
  overflow: auto;
  height: 320px;
  margin-top: 10px;
}

.overflowteach::-webkit-scrollbar {
  display: none;
}

.overflowteach {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.teachclasdate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #4C88FF;
}

.cardschooltech {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  width: auto;
  overflow-y: auto;
  overflow-x: hidden;
}


.cardschooltech::-webkit-scrollbar {
  display: none;
}

.cardschooltech {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.datetechschool {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #4C88FF;
  margin-top: 15px;
}

.scholteachname {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #1B252F;
}

.imgteach {
  width: 460px;
  height: 200px;
  margin: 0px 0px;
}

.paddingleftrihtteach {
  padding: 16px 15px;
}

.myatenteacher {
  margin: 10px 20px 0px 20px !important;
}

.charttaech {
  margin: 0px 20px 0px 20px !important;
}

.chartsecteach {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #1B252F;
}

.padfhjiko {
  margin: 30px 50px 35px 30px;
}

.classdfgghjio {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #ADB9C7;

}

.rowchar {
  width: 80%;
  height: 18px;
  background: linear-gradient(180deg, #13DA84 0%, #189C63 100%);
  border-radius: 30px;
}

.rowper {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  color: #1B252F;
}

.chartSecrty {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin: 0px 60px;
}

.Chartbackjio {
  width: 80%;
  height: 18px;
  background: #A8EACD;
  border-radius: 30px;
  margin-left: 10px;
}

.Chartbackjiorange {
  width: 80%;
  height: 18px;
  background: #FEC7B9;
  border-radius: 30px;
  margin-left: 10px;
}

.Chartbackjioyeloo {
  width: 80%;
  height: 18px;
  background: #FFEDA6;
  border-radius: 30px;
}

.Chartbackjioblue {
  width: 80%;
  height: 18px;
  background: #E3EDFF;
  border-radius: 30px;
}


.rowcharorange {
  width: 90%;
  height: 18px;
  background: linear-gradient(180deg, #FF987E 0%, #F55D36 100%);
  border-radius: 30px;
}

.rowcharblue {
  width: 85%;
  height: 18px;
  background: linear-gradient(180deg, #71A0FF 0%, #1C5AD6 100%);
  border-radius: 30px;
}

.rowcharyello {
  width: 70%;
  height: 18px;
  background: linear-gradient(180deg, #FFE991 0%, #F2C925 100%);
  border-radius: 30px;
}

.Mothachart {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.chartteachmobi {
  display: none;
}

.schollTitle {
  display: none;
}

.marginLeft-0 {
  margin-left: 0px !important;
}

/* Kampus New Milestone 2  */

.SubjectStyle {
  width: 250px;
  height: 230px;
  border-radius: 15px;
  border-color: black;
  display: flex;
  gap: 25px;
  flex-direction: column;
  border: 1px solid #dadada;
  padding: 15px;
  cursor: pointer;
}

.SubjectStyle:hover {
  border: 1px solid #adadad;
}

.borderColor {
  border: 1px solid #d8d8d8;
  padding: 10px;
  border-radius: 15px;
}

.DivBox {
  /* width:65%; */
  border: 1px solid #d8d8d8;
  padding: 10px;
}

.DivBoxBig {
  /* width:65%; */
  border: 1px solid #d8d8d8;
  padding: 10px;
  height: auto;
}

.borderCircleEdit {
  border-radius: 50%;
  cursor: pointer;
  color: white;
  background-color: green;
  font-weight: 500;
}

.borderCircleDelete {
  border-radius: 50%;
  cursor: pointer;
  color: white;
  background-color: red;
}

.Button-PaddingRight {
  padding-right: 60px;
}

.modal-content {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  /* height: 680px !important; */
}

.ButtonSize {
  font-size: 18px;
}

.modal-header {
  border-bottom: none !important;
}


/* Resposive for Assigment component  */


/* Resposive for Assigment component  */
.tox .tox-editor-header {
  z-index: auto !important;
}



.zoIndex {
  z-index: 9999;
  position: absolute;
  top: 3%;
  left: 40%;
  height: 100%;
}

.hiegtAchivementViewmore {
  height: 600px;
  width: 400px;
  display: flex;
  z-index: 99999 !important;
}

.widthyuio {
  width: 100%;
  border: 1px solid #90A4AE;
  border-radius: 15px;
  height: 560px;
  background-color: #ECEFF1;
}

.classoverflow {
  height: 280px;
  overflow: scroll !important;
  scroll-behavior: smooth !important;
}


.classoverflow::-webkit-scrollbar {
  display: none;
}

.ImgWidthZo {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid #000;
}

.classgap {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 50px 30px;
}

.classgap_top {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0px 30px;
}

.classfixedgos {
  position: fixed !important;
}

.hideattendance {
  position: absolute !important;
  top: 8% !important;
  right: 15% !important;
}

.widthResultExam {
  width: 40%;
}

.selectresultexam {
  width: 300px !important;
}

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.pastassignClass {
  position: absolute;
  right: 225px;
  top: 50px;
  cursor: pointer;
}

.pastassignClassw {
  position: absolute;
  right: 60px;
  top: 50px;
  cursor: pointer;
}

.OuterSemiDiv1ioio {
  padding: 10px;
  display: flex;
  flex-direction: column;
}


.AssignmentDivioio {
  box-sizing: border-box;
  width: auto;
  height: auto;
  border: 0.6px solid #BABABA;
  border-radius: 25px;
  padding: 20px;
  margin: 0px 20px;
}

.tableDateAssign {
  text-align: center;
  margin: 20px 20px;
  border: 1px solid #e5dfdf;
  box-shadow: 3px 3px 6px #bfc3cf, -3px -3px 6px #fff;
}

.table-responsive {
  border: 1px solid #f1f1f1f1;
}

td.textalign {
  text-align: left !important;
}