.StudentAssignment__table th{
    border: 2px solid bisque;
    background-color : bisque;
    font-weight : bold;
    font-size :19px;
}
.StudentAssignment__table td{ 
    border-top:2px solid bisque;
    border-bottom: 2px solid bisque;
    cursor: pointer;
}

.StudentAssignment__tableElements:hover{
    background-color : aliceblue;
}

.StudentAssignment__tableStart{
    border-left:2px solid bisque;
}

.StudentAssignment__tableEnd{
    border-right:2px solid bisque;
}

.border-d8d8d8{
    border : 1px solid #d8d8d8;
  }