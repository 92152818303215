
/* Jai Balaji Ui Style start */
/* Diary start*/
.diarybutton{
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .overlfowTasktoday{
    height: 200px;
    overflow: scroll;
  }

  .three div {
    font-size: 28px;
    font-weight: 500;
    /* letter-spacing: 0;
    line-height: 1.5em; */
  }


  .three span {
    font-size: 28px;
    font-weight: 500;
    /* letter-spacing: 0;
    line-height: 1.5em; */
  }

  .three div:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: #111;
  }

  .three div:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 255px;
    background-color: #333;
  }

  .diarybtnclass{
    width: 20% ;
    border-radius: 50px;
    font-size: 18px;
    background-color: #1652C9 !important;
  }

  .activediarybtn{
    box-shadow: 0 15px 20px #858ae6;
    background-color: #fff !important;
    color: #1652C9 !important;
    border: 1px solid #1652C9;
  }

  .todaytaskcalass{
    display: flex !important;
    justify-content: center !important;
    font-size: 23px;
    font-weight: 500;    
    margin-top: 50px;
  }

  .paddingtaskReport{
    padding: 0% 10%;
    height: auto;
    overflow: auto;
  }

  .taskreportprintbtn{
    padding: 0% 10%;    
  }

  .backTaskReportbtn{
    position: absolute;
    right: 12%;
  }

  .statusreportbtn{
    margin: 3% 33%;
  }

  .appraiselviewBtn{
    margin: 3% 18%;
  }
/* Diary end*/
/* Jai Balaji Ui Style end */